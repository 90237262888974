import React, { useContext } from "react";
import { Slider } from "antd";
import { } from "@ant-design/icons";
import MusicPlayerContext from "../../contexts/music-player-context";
import { capitalizeWord, convertToMinutes } from "../../utils";
import mediaItemTypes from "../../enums/media-item-types";

const TrackProgressSlider = ({ showMarks = false }) => {
  const musicPlayerCtx = useContext(MusicPlayerContext);
  const { current, trackProgress, trackDuration, setTrackProgress } =
    musicPlayerCtx;

  const marks = {};
  if (current?.transcription) {
    Object.keys(current.transcription).forEach((time) => {
      if (current.transcription[time]?.marker) {
        marks[time] = (
          <small>{capitalizeWord(current.transcription[time].marker)}</small>
        );
      }
    });
  }

  const handleSliderChange = (seconds) => {
    setTrackProgress(seconds);
  };

  if (current?.type !== mediaItemTypes.JAMTRACK) {
    return null;
  }

  return (
    <Slider
      value={trackProgress}
      min={1}
      max={trackDuration - 1}
      onChange={handleSliderChange}
      marks={showMarks ? marks : null}
      tooltip={{ formatter: (value) => convertToMinutes(value) }}
    />
  );
};

export default TrackProgressSlider;
