import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  theme,
  Spin,
  Alert,
} from "antd";
import { MailOutlined } from "@ant-design/icons";
import MusicPlayerContext from "../../contexts/music-player-context";
import { submitForgotPasswordRequest } from "../../services/authentication";
import AuthContext from "../../contexts/auth-context";
import FooterLinks from "../../components/footer-links/footer-links";

const PasswordForgot = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { pause } = useContext(MusicPlayerContext);
  pause(); // stop playing music when page loads

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  const formSumbitHandler = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    try {
      await submitForgotPasswordRequest({
        email: email,
      });

      setSubmitted(true);
      setEmail("");
    } catch (error) {
      setErrorMessage(
        "We are unable to process this request at the moment. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <div style={{ display: "block" }}>
        <Card>
          <Row justify="center">
            <Col>
              <Typography.Title
                level={2}
                style={{ color: colorPrimary }}
                className="brand-title"
              >
                Jamtrackers
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Title level={4}>Password Reset</Typography.Title>
              <Typography.Paragraph>
                Enter the email address used for the account
              </Typography.Paragraph>
              {!submitted ? (
                <Form
                  size="large"
                  name="normal_login"
                  className="login-form"
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={formSumbitHandler}
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input
                      value={email}
                      onChange={(e) => handleEmailChange(e)}
                      prefix={<MailOutlined />}
                      type="email"
                      placeholder="Email Address"
                    />
                  </Form.Item>
                  {errorMessage && (
                    <>
                      <Alert message={errorMessage} type="warning" />
                      <br />
                    </>
                  )}
                  <Row justify="center">
                    <Col>
                      {isLoading ? (
                        <Spin />
                      ) : (
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              ) : (
                <>
                  <Alert
                    message="Password Reset Request Sent"
                    description="If an account with this email exists, they will receive an email with further instructions."
                    type="success"
                    showIcon
                  />
                  <br />
                  <div style={{ textAlign: "center" }}>
                    <Button type="primary" onClick={() => navigate("/")}>
                      Back to Home
                    </Button>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Card>
        <br />
        <FooterLinks />
      </div>
    </div>
  );
};

export default PasswordForgot;
