import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { listNotifications } from "../services/notifications";

const AuthContext = React.createContext({
  token: "",
  tokenClaim: {},
  isLoggedIn: false,
  unreadNotifCount: 0,
  toolbox: [],
  login: () => {},
  logout: () => {},
  getUnreadNotifCount: () => {},
  setToolbox: () => {},
});

export const AuthContextProvider = (props) => {
  const tokenFromStorage = window.localStorage.getItem("token");
  const [token, setToken] = useState(tokenFromStorage);
  const [tokenClaim, setTokenClaim] = useState(
    tokenFromStorage ? jwt_decode(tokenFromStorage) : {}
  );
  const [unreadNotifCount, setUnreadNotifCount] = useState(0);
  const [toolbox, setToolbox] = useState([]);

  if (token) {
    const decoded = jwt_decode(token);

    const now = moment();
    const tokenExp = moment.unix(decoded.exp);

    if (now > tokenExp) {
      setToken(null);
      window.localStorage.removeItem("token");
      window.location.pathname = "/login";
    }
  }

  const loginHandler = (token) => {
    setToken(token);
    setTokenClaim(jwt_decode(token));
    window.localStorage.setItem("token", token);
  };

  const logoutHandler = () => {
    setToken(null);
    setToolbox([]);
    setTokenClaim({});
    window.localStorage.removeItem("queue"); // ensure users queue does not remain for others
    window.localStorage.removeItem("token");
  };

  const getUnreadNotifCount = async () => {
    const { results } = await listNotifications(token, {
      is_seen: false,
      limit: 1000,
    });
    setUnreadNotifCount(results.length);
  };

  const contextValue = {
    token: token,
    tokenClaim: tokenClaim,
    isLoggedIn: !!token,
    unreadNotifCount: unreadNotifCount,
    toolbox: toolbox,
    login: loginHandler,
    logout: logoutHandler,
    getUnreadNotifCount: getUnreadNotifCount,
    setToolbox: setToolbox,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
