import React, { useState } from "react";
import { Col, Row, Typography, Card, theme, Select, Button, Space, Modal } from "antd";
import { PlusOutlined, DeleteOutlined, ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { guitarChords, guitar } from "../../chords";
import { default as ReactChord } from "@tombatossals/react-chords/lib/Chord";
import { formatFromChordDb } from "../../utils";
import styles from "./chord-selector.module.scss";

const ChordPlacements = ({ note, tonality, onAddChord }) => {
  const chordPlacements =
    note && tonality
      ? guitarChords.chords[note].find((n) => n.suffix === tonality).positions
      : [];

  if (!note || !tonality) return null;

  return (
    <>
      <br />
      <Typography.Title level={5}>
        Placements for {formatFromChordDb(note)} {tonality}
      </Typography.Title>
      <Typography.Paragraph type="secondary">
        Click one of the chord charts below to add it to your progression.
      </Typography.Paragraph>
      <Row gutter={[8, 8]}>
        {chordPlacements.map((c, index) => (
          <Col
            xs={24}
            md={12}
            className={styles["chord-chart"]}
            onClick={() => onAddChord({ chord: c, tonic: note, tonality })}>
            <ReactChord chord={c} instrument={guitar} />
          </Col>
        ))}
      </Row>
    </>
  );
};


const ChordSelector = ({ chords, onChangeChords }) => {
  const [chordModalVisible, setChordModalVisible] = useState(false);
  const [newChordNote, setNewChordNote] = useState(null);
  const [newChordTonality, setNewChordTonality] = useState(null);

  const keyOptions = [
    ...Object.keys(guitarChords.chords).map((key) => {
      return {
        value: key,
        label: formatFromChordDb(key),
      };
    }),
  ];

  const tonalityOptions = newChordNote
    ? guitarChords.chords[newChordNote].map((suf) => {
      return {
        value: suf.suffix,
        label: formatFromChordDb(suf.suffix),
      };
    })
    : [];

  const deleteChord = (index) => {
    onChangeChords(chords.filter((c, i) => i !== index));
  };

  const moveChord = (chord, index, newIndex) => {
    const filteredChords = chords.filter((_, i) => i !== index);

    const newChords = [
      ...filteredChords.slice(0, newIndex),
      chord,
      ...filteredChords.slice(newIndex)
    ];

    onChangeChords(newChords);
  };

  const addChord = (newChord) => {
    onChangeChords([...chords, newChord])
    setNewChordNote(null);
    setNewChordTonality(null);
    setChordModalVisible(false);
  };

  return (
    <>
      {chords.length > 0 && (
        <>
          <Row gutter={[12, 12]} align="middle">
            {chords.map((chord, i) => {
              return (
                <Col span={12} key={i}>
                  <Card size="small">
                    <Row justify="space-between">
                      <Col>
                        <Space>
                          {i > 0 && (
                            <Button
                              size="small"
                              shape="circle"
                              onClick={() => moveChord(chord, i, i - 1)}
                            >
                              <ArrowLeftOutlined />
                            </Button>
                          )}
                          {i < chords.length - 1 && (
                            <Button
                              size="small"
                              shape="circle"
                              onClick={() => moveChord(chord, i, i + 1)}
                            >
                              <ArrowRightOutlined />
                            </Button>
                          )}
                        </Space>
                      </Col>
                      <Col>
                        <Button
                          size="small"
                          shape="circle"
                          danger
                          onClick={() => deleteChord(i)}
                          disabled={chords.length === 1}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Col>
                    </Row>
                    <ReactChord chord={chord.chord} instrument={guitar} />
                    <div style={{ textAlign: "center" }}>
                      <div>
                        <Typography.Text type="secondary">
                          Chord {i + 1}
                        </Typography.Text>
                      </div>
                      <b>{chord.tonic} {chord.tonality}</b>
                    </div>
                  </Card>
                </Col>
              )
            })}
          </Row>
          <br />
        </>
      )}

      <div style={{ textAlign: "center" }}>
        <Button size="large" onClick={() => setChordModalVisible(true)}>
          <Space>
            <PlusOutlined />
            Add Chord
          </Space>
        </Button>
      </div>

      <Modal
        open={chordModalVisible}
        onCancel={() => setChordModalVisible(false)}
      >
        <Typography.Title level={4}>
          Select Chord
        </Typography.Title>
        <Typography.Paragraph type="secondary">
          Enter the note and tonality of the chord you're looking for, then click the chord chart to select it.
        </Typography.Paragraph>
        <Row gutter={[8, 8]}>
          <Col xs={24} lg={12}>
            <Select
              style={{
                width: "100%",
              }}
              placeholder="Note"
              value={newChordNote}
              onChange={(value, option) => setNewChordNote(value)}
              allowClear
              options={keyOptions}
            />
          </Col>
          <Col xs={24} lg={12}>
            <Select
              style={{
                width: "100%",
              }}
              placeholder="Tonality"
              value={newChordTonality}
              onChange={(value, option) => setNewChordTonality(value)}
              allowClear
              options={tonalityOptions}
              disabled={!newChordNote}
            />
          </Col>
        </Row>
        <ChordPlacements
          note={newChordNote}
          tonality={newChordTonality}
          onAddChord={addChord}
        />
      </Modal>

    </>
  )
};

export default ChordSelector;