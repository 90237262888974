export const genreMap = {
  rock: "Rock",
  folk: "Folk",
  country: "Country",
  blues: "Blues",
  hip_hop: "Hip Hop",
  punk: "Punk",
  electronic: "Electronic",
  jazz: "Jazz",
  reggae: "Reggae",
  funk: "Funk",
  pop: "Pop",
  heavy_metal: "Heavy Metal",
  soul: "Soul",
  classical: "Classical",
  opera: "Opera",
  gospel: "Gospel",
};
