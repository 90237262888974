import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Skeleton,
  Space,
  Typography,
  Modal,
  notification,
  Select,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AppstoreOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../contexts/auth-context";
import FeedHeader from "../../components/typography/feed-header";
import { createChordProg, deleteChordProg, getChordProg, updateChordProg } from "../../services/chord-progressions";
import { keysMap, tonalityMap } from "../../enums/keys";
import ChordSelector from "./chord-selector";

const keyOptions = Object.keys(keysMap).map((k) => {
  return {
    value: k,
    label: keysMap[k],
  };
});

const tonalityOptions = Object.keys(tonalityMap).map((t) => {
  return {
    value: t,
    label: tonalityMap[t],
  };
});

const ChordProgEditing = () => {
  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 1 }} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 3 }} />
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small">
          <Skeleton active={true} paragraph={{ rows: 10 }} />
        </Card>
      </Col>
    </Row>
  );
};

const SectionCardTitle = ({ title, desc }) => {
  return (
    <div style={{ marginBottom: 12 }}>
      <Typography.Title level={5} style={{ marginBottom: 0 }}>
        {title}
      </Typography.Title>
      <Typography.Text type="secondary">
        <small>{desc}</small>
      </Typography.Text>
    </div>
  );
};

const ChordProgEditPage = () => {
  const navigate = useNavigate();
  const { chordProgId } = useParams();
  const { token, tokenClaim } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [key, setKey] = useState(null);
  const [tonality, setTonality] = useState(null);
  const [chords, setChords] = useState([])

  const editOrNewTitle = chordProgId ? "Edit Chord Progression" : "New Chord Progression";

  const loadData = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const chordProgData = await getChordProg(token, chordProgId);

      if (chordProgData.user.username !== tokenClaim.username) {
        navigate("/");
      }

      const { title, description, key, tonality, chords } = chordProgData;

      setTitle(title);
      setDescription(description);
      setKey(key);
      setTonality(tonality);
      setChords(chords)
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onSave = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const chordProgData = {
        title,
        description,
        key,
        tonality,
        chords,
      };

      if (chordProgId) {
        await updateChordProg(token, chordProgId, chordProgData);
      } else {
        await createChordProg(token, chordProgData);
      }

      notification.open({
        message: "Chord Progression Saved Successfully",
        placement: "bottomRight",
      });
      navigate("/chord-progressions/recent");
    } catch {
      notification.open({
        message: "An Error Occured",
        description:
          "We are unable to save this chord progressions at the moment, please try again later.",
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onConfirmDelete = async () => {
    setIsLoading(true);
    try {
      await deleteChordProg(token, { id: chordProgId });
      notification.open({
        message: "Chord Progressions Deleted",
        description:
          `${title} has been deleted.`,
        placement: "bottomRight",
      });
      navigate(`/chord-progressions/`);
    } catch {
      notification.open({
        message: "An Error Occured",
        description:
          "We are unable to delete this chord progression at the moment, please try again later.",
        placement: "bottomRight",
      });
    } finally {
      setIsLoading(false);
    }
  }


  useEffect(() => {
    if (chordProgId) {
      loadData();
    } else {
      setIsLoading(false);
    }
  }, [chordProgId]);

  if (hasError) {
    return (
      <Alert
        type="error"
        message="Something went wrong..."
        description="An error occured, we are unable to load the chord progression. Please try again
        later."
      />
    );
  }

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
        <Col>
          <FeedHeader icon={<AppstoreOutlined />} title={editOrNewTitle} />
        </Col>
      </Row>
      {isLoading ? (
        <ChordProgEditing />
      ) : (
        <>

          <Form
            initialValues={{
              remember: true,
              title,
              description,
              key,
              tonality,
            }}
            onFinish={onSave}
          >
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <Card size="small">
                  <Typography.Title level={5}>Title</Typography.Title>
                  <Form.Item
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "Please add a title.",
                      },
                    ]}
                    style={{ margin: 0 }}
                  >
                    <Input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      maxLength={400}
                    />
                  </Form.Item>
                </Card>
              </Col>
              <Col span={24}>
                <Card size="small">
                  <SectionCardTitle
                    title="Description"
                    desc="A description to provide any necessary context about this chord progression. (Optional)"
                  />
                  <Form.Item
                    name="description"
                    rules={[
                      {
                        required: false,
                        message: "Please add a description.",
                      },
                    ]}
                    style={{ margin: 0 }}
                  >
                    <Input.TextArea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      maxLength={500}
                      style={{ marginBottom: 12 }}
                      showCount
                      autoSize
                    />
                  </Form.Item>
                </Card>
              </Col>

              <Col span={24}>
                <Card size="small">
                  <SectionCardTitle
                    title="Key"
                    desc="Provide this chord progression's key (tonic and tonality)."
                  />
                  <Form.Item
                    name="key"
                    rules={[
                      {
                        required: true,
                        message: "Please input a key!",
                      },
                    ]}
                  >
                    <Select
                      value={key}
                      onChange={(e) => setKey(e)}
                      placeholder="Select key"
                      options={keyOptions}
                    />
                  </Form.Item>
                  <Form.Item
                    name="tonality"
                    rules={[
                      {
                        required: true,
                        message: "Please input a tonality!",
                      },
                    ]}
                  >
                    <Select
                      value={tonality}
                      onChange={(e) => setTonality(e)}
                      placeholder="Select tonality"
                      options={tonalityOptions}
                    />
                  </Form.Item>
                </Card>
              </Col>

              <Col span={24}>
                <Card size="small">
                  <SectionCardTitle
                    title="Chords"
                    desc="The selected chords for this progression."
                  />
                  <ChordSelector chords={chords} onChangeChords={setChords} />
                </Card>
              </Col>

              <Col span={24} style={{ textAlign: "right" }}>
                <Row justify="space-between">
                  <Col>
                    {chordProgId && (
                      <>
                        <Button danger onClick={() => setDeleteModal(true)} type="text">
                          Delete Chord Progression
                        </Button>
                        <Modal
                          open={deleteModal}
                          title="Delete Chord Progression"
                          onCancel={() => setDeleteModal(false)}
                          onOk={onConfirmDelete}
                          okText="Delete"
                          okType="danger"
                        >
                          <Typography.Text>Are you sure you want to delete the chord progression <b>{title}</b>?</Typography.Text>
                        </Modal>
                      </>
                    )}
                  </Col>
                  <Col>
                    <Space>
                      <Button onClick={() => navigate("/chord-progressions/recent")}>
                        Cancel
                      </Button>
                      <Button type="primary" htmlType="submit" disabled={chords.length === 0}>
                        Save
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default ChordProgEditPage;
