import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Typography,
  notification,
} from "antd";
import {
  HeartFilled,
  HeartOutlined,
} from "@ant-design/icons";
import UserProfilePopover from "../../user-profile-popover/user-profile-popover";
import defaultCoverImage from "../../../media/images/guitar-player.jpg";
import styles from "./course-card.module.scss";
import CourseActionButtons from "./course-action-buttons";
import AuthContext from "../../../contexts/auth-context";
import { toggleCourseFavorite } from "../../../services/courses";

const CourseCard = ({ course }) => {
  const { token, isLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const cardRef = useRef();
  const paddingRef = useRef();

  const [isFavorite, setIsFavorite] = useState(course.is_favorite);

  const handleFavoriteClick = async () => {
    try {
      const data = await toggleCourseFavorite(token, course);
      setIsFavorite(data.is_favorite)

      notification.open({
        message: isFavorite
          ? "Course Unfavorited"
          : "Course Favorited",
        description: `${course.title} has been ${isFavorite ? "removed" : "added"
          } to your favorites list.`,
        placement: "bottomRight",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const numLessons = course.lessons?.length || 0;

  const actions = !isLoggedIn ? [] : [
    <div onClick={handleFavoriteClick}>
      {isFavorite ? <HeartFilled /> : <HeartOutlined />}
    </div>
  ];

  useEffect(() => {
    // hack to get cards on same row the same height. Ant Design cards are difficult because of actions
    const colHeight = document.getElementById(course.id).parentElement.clientHeight
    if (colHeight !== cardRef.current?.clientHeight) {
      const difference = colHeight - cardRef.current.clientHeight;
      paddingRef.current.style.height = difference + "px"
    };
  }, [])

  return (
    <Card
      id={course.id}
      size="small"
      className={styles["card"]}
      cover={
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", top: 12, right: 12 }} className={styles["card-image-button"]}>
            <CourseActionButtons course={course} />
          </div>
          <img
            alt={course.title}
            style={{
              height: 150,
              width: "100%",
              objectFit: "cover",
            }}
            src={course.photo || defaultCoverImage} // TODO: change default image
          />
        </div>
      }
      ref={cardRef}
      actions={actions}
    >
      <Typography.Title
        level={5}
        className={styles["course-title"]}
        onClick={() => navigate(`/courses/${course.id}`)}>
        {course.title}
      </Typography.Title>
      <div>
        <Typography.Text type="secondary"><small>By {''}</small></Typography.Text>
        <UserProfilePopover user={course.user} isFollowing={course.in_following_feed}>
          <Typography.Link><small>{course.user.username}</small></Typography.Link>
        </UserProfilePopover>
      </div>
      <div ref={paddingRef} />
      <div style={{ textAlign: "right" }}>
        <Typography.Text type="secondary">
          <small>
            {`${numLessons} Lesson${numLessons !== 1 ? "s" : ""}`}
          </small>
        </Typography.Text>
      </div>
    </Card >
  )
}

export default CourseCard;