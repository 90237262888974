import React from "react";
import { Carousel } from "antd";
import { useNavigate } from "react-router-dom";
import HeroHighlightCard from "../cards/hero-highlight-card";

const HeroCardCarousel = ({ highlights }) => {
  const navigate = useNavigate()

  return (
    <Carousel
      autoplay
      effect="fade"
      dotPosition="top"
      style={{ maxWidth: "calc(100vw - 30px)" }} // because the Carousel component seems broken?
    >
      {highlights
        .sort((a, b) => a.order - b.order)
        .map((c) => (
          <HeroHighlightCard
            title={c.title}
            description={c.description}
            image={c.image}
            buttonText={c.button_text}
            onClickButton={() => navigate(c.button_path)}
          />
        ))}
    </Carousel>
  );
};

export default HeroCardCarousel;