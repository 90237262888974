import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Typography,
  Button,
  Card,
  theme,
  Space,
  notification,
  Spin,
  Switch,
} from "antd";
import Container from "../../components/layout/container/container";
import AuthContext from "../../contexts/auth-context";
import { getJamtrack, updateJamtrack } from "../../services/jamtracks";
import TranscribeControls from "./transcribe-controls";
import TranscriptionList from "./transcription-list";
import MusicPlayerContext from "../../contexts/music-player-context";
import FooterLinks from "../../components/footer-links/footer-links";

const Transcribe = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { pause } = useContext(MusicPlayerContext);
  pause(); // stop playing music when page loads

  const { token, tokenClaim } = useContext(AuthContext);
  const { jamtrackId } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [jamtrack, setJamtrack] = useState({});
  const [transcription, setTranscription] = useState([]);
  const [initialHideTranscription, setInitialHideTranscription] =
    useState(false);

  const loadData = async () => {
    setIsLoading(true);
    setHasError(true);
    try {
      const jamtrackData = await getJamtrack(token, jamtrackId);

      if (jamtrackData.user.username !== tokenClaim.username) {
        // TODO: redirect to 404 page
        navigate("/");
      }

      setJamtrack(jamtrackData);
      setTranscription(jamtrackData.transcription);
      setInitialHideTranscription(jamtrackData.hide_transcription);
    } catch (error) {
      setHasError(true);
      navigate("/");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteChord = (key) => {
    const newTranscription = { ...transcription };
    delete newTranscription[key];
    setTranscription(newTranscription);
  };

  const handleUpdateChord = (key, chordData) => {
    const newTranscription = { ...transcription };
    newTranscription[key] = chordData;
    setTranscription(newTranscription);
  };

  const onToggleTranscripVisiibility = (checked) => {
    const updatedJamtrack = { ...jamtrack, hide_transcription: checked };
    setJamtrack(updatedJamtrack);
  };

  const handleSave = async () => {
    try {
      const transcriptionExists = Object.keys(transcription).length > 0;
      await updateJamtrack(token, jamtrack, {
        hide_transcription: jamtrack.hide_transcription,
        transcription: transcriptionExists ? transcription : null,
      });
      notification.open({
        message: "Transcription Saved",
        description: `The transcription for ${jamtrack.title} has been saved succesfully.`,
        placement: "bottomRight",
      });
      navigate("/");
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <div
        style={{
          padding: "12px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <div style={{ display: "block" }}>
          {isLoading ? (
            <Row justify="center">
              <Spin size="large" />
            </Row>
          ) : (
            <Row justify="center">
              <Col xs={24} md={18} lg={12}>
                <Card>
                  <div style={{ textAlign: "center" }}>
                    <Typography.Title
                      level={2}
                      style={{ color: colorPrimary }}
                      className="brand-title"
                    >
                      Jamtrackers
                    </Typography.Title>
                  </div>
                  <Typography.Title level={4}>Transcribe</Typography.Title>
                  <Typography.Paragraph>
                    A transcription marks when chord changes take place within
                    your jam track. They allow users to follow along to your
                    track, knowing which chords to play and what chords to
                    expect next.
                  </Typography.Paragraph>
                  <div style={{ textAlign: "center" }}>
                    <Typography.Paragraph strong>
                      {jamtrack.title}
                    </Typography.Paragraph>
                  </div>
                  {jamtrack && (
                    <TranscribeControls
                      jamtrack={jamtrack}
                      transcription={transcription}
                      onChangeTranscription={setTranscription}
                    />
                  )}
                  <br />
                  <Row justify="space-between">
                    <Col>
                      <Space>
                        <Switch
                          checked={jamtrack.hide_transcription}
                          onChange={onToggleTranscripVisiibility}
                        />
                        <Typography.Text type="secondary">
                          Hide transcription
                        </Typography.Text>
                      </Space>
                    </Col>
                    <Col>
                      <Space>
                        <Button onClick={() => navigate("/")}>Cancel</Button>
                        <Button
                          type="primary"
                          onClick={handleSave}
                          disabled={
                            jamtrack.transcription === transcription &&
                            jamtrack.hide_transcription ===
                            initialHideTranscription
                          }
                        >
                          Save
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
          <br />
          <Row justify="center">
            <Col xs={24} md={18} lg={12}>
              {transcription && Object.keys(transcription).length > 0 && (
                <TranscriptionList
                  transcription={transcription}
                  onDeleteChord={handleDeleteChord}
                  onUpdateChord={handleUpdateChord}
                />
              )}
            </Col>
          </Row>
          <br />
          <FooterLinks />
        </div>
      </div>
    </Container>
  );
};

export default Transcribe;
