import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ConfigProvider } from "antd";
import reportWebVitals from "./reportWebVitals";
import { MusicPlayerContextProvider } from "./contexts/music-player-context";
import { AuthContextProvider } from "./contexts/auth-context";
import { FeedContextProvider } from "./contexts/feed-context";
import ErrorBoundary from "./components/error-boundary/ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#464775",
        colorInfo: "#4548d5",
      },
    }}
  >
    <ErrorBoundary>
      <AuthContextProvider>
        <MusicPlayerContextProvider>
          <FeedContextProvider>
            <App />
          </FeedContextProvider>
        </MusicPlayerContextProvider>
      </AuthContextProvider>
    </ErrorBoundary>
  </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
