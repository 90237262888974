const baseUrl = process.env.REACT_APP_BASE_URL;

export const getToken = async (credentials) => {
  const data = {
    method: "POST",
    body: JSON.stringify(credentials),
    headers: {
      "Content-Type": "application/json",
    },
  };

  const url = `${baseUrl}/api/auth/jwt/token/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const submitForgotPasswordRequest = async (body) => {
  const data = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  };

  const url = `${baseUrl}/api/password_reset/`;
  const response = await fetch(url, data);
  if (response.status !== 201) {
    throw Error;
  }
  return response.json();
};

export const getPasswordResetRequest = async (id) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const url = `${baseUrl}/api/password_reset/${id}/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const confirmPasswordResetRequest = async (id, body) => {
  const data = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  };

  const url = `${baseUrl}/api/password_reset/${id}/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    const errors = await response.json();
    throw Error(JSON.stringify(errors));
  }
  return response.json();
};
