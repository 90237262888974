import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Result,
  Button,
  Spin,
  Dropdown,
  Space,
} from "antd";
import {
  ExclamationCircleOutlined,
  UsergroupAddOutlined,
  HeartOutlined,
  StarOutlined,
  DownOutlined,
  CommentOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { listBlogs } from "../../services/blogs";
import AuthContext from "../../contexts/auth-context";
import BlogCard from "../../components/cards/blog-card/blog-card";
import SkeletonBlogCard from "../../components/cards/skeleton-blog-card";
import InFeedAd from "../../components/ads/in-feed-ad/in-feed-ad";
import { getAdFrequency } from "../../utils";

const loadingCards = [1, 2, 3].map((i) => {
  return (
    <Col key={i} span={24}>
      <SkeletonBlogCard />
    </Col>
  );
});

const DefaultFeedSelector = () => {
  const { pathname } = useLocation();
  const { token, tokenClaim: isCreator } = useContext(AuthContext);
  const navigate = useNavigate();

  const dropdownItems = [
    {
      key: "/blogs/recent",
      label: "Recently Added",
    },
  ];

  if (token) {
    dropdownItems.push(
      {
        key: "/blogs/following",
        label: "Following",
      },
      {
        key: "/blogs/favorites",
        label: "My Favorites",
      }
    );
  }
  if (isCreator) {
    dropdownItems.push({
      key: "/blogs/drafts",
      label: "My Drafts",
    });
  }

  const handleDropdownClick = ({ item, key, keyPath, domEvent }) => {
    navigate(key);
  };

  const selectedDropdown = dropdownItems.find((i) => pathname.includes(i.key));

  return (
    <Dropdown
      menu={{
        onClick: handleDropdownClick,
        selectedKeys: [pathname],
        items: dropdownItems,
      }}
      trigger="click"
    >
      <Button type="text">
        <Space>
          <CommentOutlined />
          <b>Blogs</b>
          {selectedDropdown.label}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

const BlogListPage = ({
  initialParams,
  feedTitle,
  titleComponent = <DefaultFeedSelector />,
}) => {
  const { token } = useContext(AuthContext);
  const [feed, setFeed] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [canPaginate, setCanPaginate] = useState(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const getEmptyMessage = () => {
    if (location.pathname === "/blogs/following" && feed.length === 0) {
      return (
        <Result
          title="Follow musicians you like to generate a custom feed."
          subTitle="No results yet. Start following users to populate this feed."
          icon={<UsergroupAddOutlined />}
        />
      );
    }

    if (location.pathname === "/blogs/favorites" && feed.length === 0) {
      return (
        <Result
          title="Save your favorite blogs and find them here."
          subTitle="Nothing saved yet. Start adding favorites to populate this page."
          icon={<HeartOutlined />}
        />
      );
    }

    if (location.pathname === "/blogs/featured" && feed.length === 0) {
      return (
        <Result
          title="There currently aren't any featured blogs."
          subTitle="Check back later to find blogs that have been recent favorites among the community."
          icon={<StarOutlined />}
        />
      );
    }

    if (location.pathname === "/blogs/drafts" && feed.length === 0) {
      return (
        <Result
          title="You currently don't have any draft blogs."
          icon={<EditOutlined />}
          extra={
            <Button onClick={() => navigate("/blogs/new")}>
              Create Blog
            </Button>
          }
        />
      );
    }
    return (
      <Result
        title="There currently aren't any published blogs."
        icon={<StarOutlined />}
      />
    );
  };

  const loadData = async (params) => {
    setCanPaginate(true);
    setIsLoading(true);
    setHasError(false);
    try {
      const { count, results } = await listBlogs(token, {
        ...initialParams,
        ...params,
      });
      if (count === results.length) setCanPaginate(false);
      setFeed(results);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onPaginate = async (params) => {
    setIsPaginationLoading(true);
    setHasError(false);
    try {
      const { count, results } = await listBlogs(token, {
        ...initialParams,
        ...params,
        offset: feed.length,
      });
      const newBlogs = [...feed, ...results];
      if (count === newBlogs.length) setCanPaginate(false);
      setFeed(newBlogs);
    } catch {
      setHasError(true);
    } finally {
      setIsPaginationLoading(false);
    }
  };

  useEffect(() => {
    loadData({});
  }, [location.pathname]);

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Col>{titleComponent}</Col>
      </Row>

      <Row gutter={[0, 12]} justify="center">
        {isLoading && <>{loadingCards}</>}
        {hasError && !isLoading && (
          <Result
            title="Uh, something went wrong.."
            subTitle="Try refreshing the page, if that doesn't do it then something is broken."
            icon={<ExclamationCircleOutlined />}
          />
        )}
        {!isLoading && !hasError && feed.length > 0 && (
          <>
            {feed.map((blog, i) => {
              return (
                <>
                  <Col span={24} key={blog.id}>
                    <BlogCard blog={blog} />
                  </Col>
                  {(i + 1) % getAdFrequency() == 0 && (
                    <InFeedAd key={blog.id} />
                  )}
                </>
              );
            })}
          </>
        )}
        {!isLoading && !hasError && feed.length === 0 && (
          <Col span={24}>{getEmptyMessage()}</Col>
        )}
        {!isLoading && !hasError && canPaginate && (
          <Col>
            {isPaginationLoading ? (
              <Spin />
            ) : (
              <Button type="link" onClick={() => onPaginate(initialParams)}>
                Load More
              </Button>
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

export default BlogListPage;
