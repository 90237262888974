import React, { useContext, useEffect, useState } from "react";
import {
  ArrowRightOutlined,
} from "@ant-design/icons";
import AuthContext from "../../contexts/auth-context";
import { Button, Card, Carousel, Col, Row, Space, Tag, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { listHighlights } from "../../services/faqs";
import { listJamtracks } from "../../services/jamtracks";
import JamtrackCard from "../../components/cards/jamtrack-card";
import BlogCard from "../../components/cards/blog-card/blog-card";
import ChordProgCard from "../../components/cards/chord-prog-card/chord-prog-card";
import { genreMap } from "../../enums/genres";
import GenreCard from "../../components/cards/genre-card";
import { listTools } from "../../services/tools";
import { listBlogs } from "../../services/blogs";
import { listChordProgs } from "../../services/chord-progressions";
import ToolCard from "../../components/cards/tool-card/tool-card";
import FeedContext from "../../contexts/feed-context";
import HomeSkeleton from "./home-skeleton";
import TutorialCarousel from "./carousels/tutorial-carousel";
import DrumBassCard from "./cards/drum-bass-card/drum-bass-card";
import drumImage from "./cards/drum-bass-card/drum-icon.svg"
import bassImage from "./cards/drum-bass-card/bass-icon.svg"
import genreBackground from "./media/genre-background.svg"
import transcribeBackground from "./media/transcribe-background.svg"
import creatorBackground from "./media/creator-background.svg"
import youtubeBackground from "./media/youtube-background.svg"
import redditBackground from "./media/reddit-background.svg"
import HeroCardCarousel from "../../components/hero-card-carousel/hero-card-carousel";

const HomePage = () => {
  const navigate = useNavigate();
  const {
    isLoggedIn,
    token,
  } = useContext(AuthContext);
  const { feed, setFeed } = useContext(FeedContext); // jamtracks

  const [highlights, setHighlights] = useState([]);
  const [chordProgs, setChordProgs] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [tools, setTools] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const genreKeys = Object.keys(genreMap);

  const loadData = async () => {
    setHasError(false);
    setIsLoading(true);

    try {
      const { results: highlightData } = await listHighlights(null, { category: "home" });
      const { results: jamtrackData } = await listJamtracks(token, {
        limit: 5,
        no_bass: false,
        no_drums: false,
        is_featured: true,
      });
      const { results: chordProgData } = await listChordProgs(token, {
        limit: 3,
        is_featured: true,
      });
      const { results: blogsData } = await listBlogs(token, {
        limit: 4,
        is_published: true,
        is_featured: true,
      });
      const { results: toolsData } = await listTools(token, {
        limit: 3,
      });

      setHighlights(highlightData);
      setChordProgs(chordProgData);
      setFeed(jamtrackData);
      setTools(toolsData);
      setBlogs(blogsData);
    } catch {
      console.log("error loading data");
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading) return <HomeSkeleton />;

  return (
    <>
      {isLoggedIn ? (
        <>
          {highlights.length > 0 && (
            <>
              <Typography.Title level={4}>Discover</Typography.Title>
              <HeroCardCarousel highlights={highlights} />
            </>
          )
          }
        </>
      ) : (
        <>
          <Typography.Title level={4}>Quick Start</Typography.Title>
          <TutorialCarousel />
        </>

      )}


      <br />
      <Typography.Title level={4} style={{ marginBottom: 0 }}>Jam Tracks</Typography.Title>
      <Typography.Paragraph type="secondary" style={{ marginBottom: 12 }}>
        Jam Tracks help you improve your improvisational skills. They provide the musical foundation of a song, allowing you to practice soloing on top.
      </Typography.Paragraph>
      <Row gutter={[12, 12]} justify="end">
        {feed.map((jt) => (
          <Col span={24}>
            <JamtrackCard jamtrack={jt} />
          </Col>
        ))}
      </Row>
      <Row justify="end" style={{ marginTop: 12 }}>
        <Col>
          <Button type="link" size="small" onClick={() => navigate("/jamtracks/lead")}>
            More Tracks for Lead Players
            <ArrowRightOutlined />
          </Button>
        </Col>
      </Row>
      <br />
      <br />
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <DrumBassCard image={bassImage} smallText="Jam Tracks for" bigText="Bass Players" navigateTo="/jamtracks/bass" />
        </Col>
        <Col span={12}>
          <DrumBassCard image={drumImage} smallText="Jam Tracks for" bigText="Drummers" navigateTo="/jamtracks/drums" />
        </Col>
      </Row>
      <br />
      <Card
        size="small"
        style={{
          overflow: "hidden",
          backgroundImage: `url(${genreBackground})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          maxWidth: "calc(100vw - 30px)",  // because the Carousel component seems broken?
        }}
      >
        <Typography.Title level={5}>Jam Tracks by Genre</Typography.Title>
        <Carousel
          autoplay
          dots={false}
          style={{ marginRight: -18, marginLeft: -18 }}
        >
          <div>
            <Row gutter={[12, 12]} style={{ marginLeft: 12, marginRight: 12 }}>
              {genreKeys.slice(0, 6).map((g) => (
                <Col xs={{ span: 12 }} lg={{ span: 8 }} >
                  <GenreCard name={genreMap[g]} encodedName={g} />
                </Col>
              ))}
            </Row>
          </div>
          <div>
            <Row gutter={[12, 12]} style={{ marginLeft: 12, marginRight: 12 }}>
              {genreKeys.slice(7, 13).map((g) => (
                <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                  <GenreCard name={genreMap[g]} encodedName={g} />
                </Col>
              ))}
            </Row>
          </div>
        </Carousel>
        <Row justify="end" style={{ marginTop: 10 }}>
          <Col>
            <Button type="link" size="small" onClick={() => navigate("/jamtracks/genres")}>
              All Genres
              <ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
      </Card >
      <br />
      <Card
        size="small"
        style={{
          overflow: "hidden",
          backgroundImage: `url(${transcribeBackground})`,
          backgroundPosition: "right",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Row>
          <Col sm={24} md={16}>
            <Typography.Title level={5}>
              Jam Tracks with Live Chords
            </Typography.Title>
            <Typography.Paragraph>
              Live Chords is a feature that allows you to follow along with chord changes in real time. This way you'll know exactly what to play, and when.
            </Typography.Paragraph>
            <Button type="link" size="small" onClick={() => navigate("/jamtracks/lead?is_transcribed=1")}>
              Browse jam tracks with Live Chords
              <ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
      </Card>
      <Row justify="end" style={{ marginTop: 12 }}>
        <Col>
          <Button type="link" size="small" onClick={() => navigate("/jamtracks/")}>
            Browse All Jam Tracks
            <ArrowRightOutlined />
          </Button>
        </Col>
      </Row>

      {
        chordProgs.length > 0 && (
          <>
            <br />
            <Space>
              <Typography.Title level={4} style={{ marginBottom: 0 }}>
                Chord Progressions
              </Typography.Title>
            </Space>
            <Typography.Paragraph type="secondary" style={{ marginBottom: 12 }}>
              Quickly jump into a jam with a curated chord progression. Use these for personal practice, or as a starting point when playing with others.
            </Typography.Paragraph>
            <Row gutter={[12, 12]} justify="end">
              {chordProgs.map((c) => (
                <Col span={24}>
                  <ChordProgCard chordProg={c} onDelete={() => console.log("delete")} />
                </Col>
              ))}
              <Col>
                <Button type="link" size="small" onClick={() => navigate("/chord-progressions")}>
                  More Chord Progressions
                  <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </>
        )
      }

      {
        blogs.length > 0 && (
          <>
            <br />
            <Space>
              <Typography.Title level={4} style={{ marginBottom: 0 }}>
                Blogs
              </Typography.Title>
            </Space>
            <Typography.Paragraph type="secondary" style={{ marginBottom: 12 }}>
              Our blogs provided you with a feed of light reading material to help drive inspiration and build knowledge.
            </Typography.Paragraph>
            <Row gutter={[12, 12]} justify="end">
              {blogs.map((b) => (
                <Col span={24}>
                  <BlogCard blog={b} />
                </Col>
              ))}
              <Col>
                <Button type="link" size="small" onClick={() => navigate("/blogs")}>
                  More Blogs
                  <ArrowRightOutlined />
                </Button>
              </Col>
            </Row>
          </>
        )
      }
      <br />
      <Typography.Title level={4} style={{ marginBottom: 0 }}>Musical Tools</Typography.Title>
      <Typography.Paragraph type="secondary" style={{ marginBottom: 12 }}>
        We provide a variety of handy tools to help you learn and practice your instrument.
      </Typography.Paragraph>
      <Row gutter={[12, 12]} justify="end">
        {tools
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((t) => (
            <Col span={24}>
              <ToolCard tool={t} />
            </Col>
          ))}
        <Col>
          <Button type="link" size="small" onClick={() => navigate("/tools")}>
            More Tools
            <ArrowRightOutlined />
          </Button>
        </Col>
      </Row>
      <br />
      <Typography.Title level={4} style={{ marginBottom: 0 }}>Contribute</Typography.Title>
      <Typography.Paragraph type="secondary">
        Learn more about how you can play your own role in Jamtrackers.
      </Typography.Paragraph>
      <Card
        size="small"
        style={{
          overflow: "hidden",
          backgroundImage: `url(${creatorBackground})`,
          backgroundPosition: "right",
          backgroundSize: "200px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Row>
          <Col xs={20} md={18}>
            <Typography.Title level={5}>
              Become a Creator
            </Typography.Title>
            <Typography.Paragraph>
              Do you create content such as jam tracks, blogs, lessons, or more? You can become a content creator and receive a dedicated profile where you'll be able to share your content and get paid.
            </Typography.Paragraph>
            <Button type="link" size="small" onClick={() => navigate("/register")}>
              Create an Account
              <ArrowRightOutlined />
            </Button>
            <Button type="link" size="small" onClick={() => navigate("/creator-application")}>
              Apply to Become a Creator
              <ArrowRightOutlined />
            </Button>
          </Col>
        </Row>
      </Card>
      <br />
      {/* <Card
        size="small"
        style={{
          overflow: "hidden",
          backgroundImage: `url(${redditBackground})`,
          backgroundPosition: "right",
          backgroundSize: "200px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Row>
          <Col xs={20} md={18}>
            <Typography.Title level={5}>
              Join the Subreddit
            </Typography.Title>
            <Typography.Paragraph>
              The subreddit <i>r/jamtrackers</i> is the dedicated spot where you can get latest news, provide feedback, or even share a video a solo!
            </Typography.Paragraph>
            <Button type="link" size="small">
              <a href="https://www.reddit.com/r/jamtrackers/" target="_blank">
                <Space>
                  Join Subreddit
                  <ArrowRightOutlined />
                </Space>
              </a>
            </Button>
          </Col>
        </Row>
      </Card>
      <br /> */}
      <Card
        size="small"
        style={{
          overflow: "hidden",
          backgroundImage: `url(${youtubeBackground})`,
          backgroundPosition: "right",
          backgroundSize: "200px",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Row>
          <Col xs={20} md={18}>
            <Typography.Title level={5}>
              Subscribe on YouTube
            </Typography.Title>
            <Typography.Paragraph>
              We post a a selection of our tracks on YouTube so that we can introduce Jamtrackers to new users.
            </Typography.Paragraph>
            <Button type="link" size="small">
              <a href="https://www.youtube.com/channel/UCMXUaBzHaYNnDwrr-VyeLIA" target="_blank">
                <Space>
                  Visit Channel
                  <ArrowRightOutlined />
                </Space>
              </a>
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default HomePage;
