import React, { useContext, useEffect, useState } from "react";
import { Menu } from "antd";
import {
  LoginOutlined,
  UserOutlined,
  ToolOutlined,
  HomeOutlined,
  CustomerServiceOutlined,
  ReadOutlined,
  SearchOutlined,
  CommentOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../../contexts/auth-context";

const SiderMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isLoggedIn,
    tokenClaim: {
      username,
      is_staff: isStaff,
      is_creator: isCreator,
      user_id: userId,
    },
    logout,
  } = useContext(AuthContext);

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);

  const menuItems = [
    {
      key: "/",
      icon: React.createElement(HomeOutlined),
      label: "Home",
    },
    {
      key: "/courses",
      icon: React.createElement(ReadOutlined),
      label: "Courses",
    },
    {
      key: "/jamtracks",
      icon: React.createElement(CustomerServiceOutlined),
      label: "Jam Tracks",
    },
    {
      key: "/chord-progressions",
      icon: React.createElement(AppstoreOutlined),
      label: "Chord Progressions",
    },
    {
      key: "/blogs",
      icon: React.createElement(CommentOutlined),
      label: "Blogs",
    },
    {
      key: "/tools",
      icon: React.createElement(ToolOutlined),
      label: "Tools",
    },
    {
      key: "/search",
      icon: React.createElement(SearchOutlined),
      label: "Search",
    },
  ];

  if (!isLoggedIn) {
    menuItems.push({
      key: "/login",
      icon: React.createElement(LoginOutlined),
      label: "Login",
    });
  } else {
    const accountMenuChildren = [
      {
        key: "/notifications",
        label: "Notifications",
      },
      {
        key: "/account-settings",
        label: "Account Settings",
      },
      {
        key: "/logout",
        label: "Logout",
      },
    ];

    if (isCreator && userId) {
      accountMenuChildren.splice(0, 0, {
        key: `/musicians/${userId}`,
        label: "My Profile",
      });
    }

    if (isStaff) {
      accountMenuChildren.splice(2, 0, {
        key: "/staff/youtube-capture",
        label: "Capture Video",
      });
    }

    menuItems.push({
      key: "/my-account",
      icon: React.createElement(UserOutlined),
      label: username,
      children: accountMenuChildren,
    });
  }

  const getInitialSelectedKeys = () => {
    if (location.pathname === "/") {
      setSelectedKeys("/");
    } else {
      const keys = [];
      menuItems.forEach((i) => {
        if (i.key !== "/") {
          keys.push(i.key);
          if (i.children) {
            i?.children.forEach((c) => keys.push(c.key));
          }
        }
      });

      let defaultSelected = keys.filter((i) => location.pathname.includes(i));
      if (defaultSelected.length === 0) defaultSelected = ["/"];

      setSelectedKeys(defaultSelected);
    }
  };

  const handleSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    if (key === "/logout") {
      logout();
      navigate("/");
      setSelectedKeys(["/"]);
      setOpenKeys([]);
    } else {
      navigate(key);
      setSelectedKeys([key]);
    }
  };

  const rootSubmenuKeys = [
    "/home",
    "/jamtracks",
    "/courses",
    "/blogs",
    "/tools",
    "/my-account",
  ];
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    getInitialSelectedKeys();
  }, [location]);

  return (
    <div style={{ overflow: "auto" }}>
      <Menu
        onClick={handleSelect} // TODO: this should be onSelect
        onOpenChange={onOpenChange}
        style={{ minHeight: "500px" }}
        theme="dark"
        mode="inline"
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        items={menuItems}
      />
    </div>
  );
};

export default SiderMenu;
