import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Result,
  Button,
  Spin,
  Dropdown,
  Space,
  Badge,
  theme,
} from "antd";
import {
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
  UsergroupAddOutlined,
  HeartOutlined,
  StarOutlined,
  DownOutlined,
  UpOutlined,
  FilterOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import SkeletonJamtrackCard from "../../components/cards/skeleton-jamtrack-card";
import { listJamtracks } from "../../services/jamtracks";
import JamtrackCard from "../../components/cards/jamtrack-card";
import FilterSelectors from "../../components/filter-selectors";
import AuthContext from "../../contexts/auth-context";
import FeedContext from "../../contexts/feed-context";
import { getAdFrequency, getInitialParamsDict } from "../../utils";
import InFeedAd from "../../components/ads/in-feed-ad/in-feed-ad";

const loadingCards = [1, 2, 3].map((i) => {
  return (
    <Col key={i} span={24}>
      <SkeletonJamtrackCard />
    </Col>
  );
});

const DefaultFeedSelector = () => {
  const { pathname } = useLocation();
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  const dropdownItems = [
    {
      key: "/jamtracks/lead",
      label: "For Lead Players",
    },
    {
      key: "/jamtracks/bass",
      label: "For Bass Players",
    },
    {
      key: "/jamtracks/drums",
      label: "For Drummers",
    },
    {
      key: "/jamtracks/recent",
      label: "Recently Added",
    },
  ];

  if (token)
    dropdownItems.push(
      {
        key: "/jamtracks/following",
        label: "Following",
      },
      {
        key: "/jamtracks/favorites",
        label: "My Favorites",
      }
    );

  const handleDropdownClick = ({ item, key, keyPath, domEvent }) => {
    navigate(key);
  };

  return (
    <Dropdown
      menu={{
        onClick: handleDropdownClick,
        selectedKeys: [pathname],
        items: dropdownItems,
      }}
      trigger="click"
    >
      <Button type="text">
        <Space>
          <CustomerServiceOutlined />
          <b>Jam Tracks</b>
          {dropdownItems.find((i) => pathname.includes(i.key)).label}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

const JamtrackListPage = ({
  initialParams,
  feedTitle,
  titleComponent = <DefaultFeedSelector />,
}) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { token } = useContext(AuthContext);
  const { feed, setFeed } = useContext(FeedContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [canPaginate, setCanPaginate] = useState(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [searchFilters, setSearchFilters] = useState(
    getInitialParamsDict(location.search)
  );
  const [showFilters, setShowFilters] = useState(false);

  const { pathname } = useLocation(); // TODO this is called twice...

  const activeFilterCount = Object.keys(searchFilters).length;

  const getEmptyMessage = () => {
    if (Object.keys(searchFilters).length > 0 && feed.length === 0) {
      return (
        <Result
          title="No results"
          subTitle="Sorry, we didn't find any results that match your search."
          icon={<QuestionCircleOutlined />}
          extra={
            <Button onClick={() => onApplyFilter({})}>Clear Filters</Button>
          }
        />
      );
    }

    if (pathname === "/jamtracks/following" && feed.length === 0) {
      return (
        <Result
          title="Follow musicians you like to generate a custom feed."
          subTitle="No results yet. Start following users to populate this feed."
          icon={<UsergroupAddOutlined />}
        />
      );
    }

    if (pathname === "/jamtracks/favorites" && feed.length === 0) {
      return (
        <Result
          title="Save your favorite jamtracks and find them here."
          subTitle="Nothing saved yet. Start adding favorites to populate this page."
          icon={<HeartOutlined />}
        />
      );
    }

    if (pathname === "/jamtracks/featured" && feed.length === 0) {
      return (
        <Result
          title="There currently aren't any featured jamtracks."
          subTitle="Check back later to find jamtracks that have been recent favorites among the community."
          icon={<StarOutlined />}
        />
      );
    }
  };

  const loadData = async (params) => {
    setCanPaginate(true);
    setIsLoading(true);
    setHasError(false);
    try {
      const { count, results } = await listJamtracks(token, {
        ...initialParams,
        ...params,
      });
      if (count === results.length) setCanPaginate(false);
      setFeed(results);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onPaginate = async (params) => {
    setIsPaginationLoading(true);
    setHasError(false);
    try {
      const { count, results } = await listJamtracks(token, {
        ...initialParams,
        ...params,
        offset: feed.length,
      });
      const newJamtracks = [...feed, ...results];
      if (count === newJamtracks.length) setCanPaginate(false);
      setFeed(newJamtracks);
    } catch {
      setHasError(true);
    } finally {
      setIsPaginationLoading(false);
    }
  };

  const onApplyFilter = (filters) => {
    const newParamString = new URLSearchParams(filters).toString();
    const newUrl = pathname + (newParamString ? `?${newParamString}` : "");
    window.history.replaceState(null, "", newUrl);
    setSearchFilters(filters);
    setFeed([]);
    setCanPaginate(true);

    loadData(filters);
  };

  useEffect(() => {
    loadData(searchFilters);
  }, [pathname]);

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: 10 }} align="middle">
        <Col>{titleComponent}</Col>
        <Col>
          <Badge count={activeFilterCount} color={colorPrimary}>
            <Button type="text" onClick={() => setShowFilters(!showFilters)}>
              <Space>
                <FilterOutlined />
                {showFilters ? <UpOutlined /> : <DownOutlined />}
              </Space>
            </Button>
          </Badge>
        </Col>
      </Row>

      {showFilters && (
        <>
          <FilterSelectors
            filters={searchFilters}
            onApplyFilter={onApplyFilter}
          />
          <br />
        </>
      )}

      <Row gutter={[0, 12]} justify="center">
        {isLoading && <>{loadingCards}</>}
        {hasError && !isLoading && (
          <Result
            title="Uh, something went wrong.."
            subTitle="Try refreshing the page, if that doesn't do it then something is broken."
            icon={<ExclamationCircleOutlined />}
          />
        )}
        {!isLoading && !hasError && feed.length > 0 && (
          <>
            {feed.map((track, i) => {
              return (
                <>
                  <Col span={24} key={track.id}>
                    <JamtrackCard jamtrack={track} />
                  </Col>
                  {(i + 1) % getAdFrequency() == 0 && (
                    <InFeedAd key={track.id} />
                  )}
                </>
              );
            })}
          </>
        )}
        {!isLoading && !hasError && feed.length === 0 && (
          <Col span={24}>{getEmptyMessage()}</Col>
        )}
        {!isLoading && !hasError && canPaginate && (
          <Col>
            {isPaginationLoading ? (
              <Spin />
            ) : (
              <Button type="link" onClick={() => onPaginate(searchFilters)}>
                Load More
              </Button>
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

export default JamtrackListPage;
