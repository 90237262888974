const baseUrl = process.env.REACT_APP_BASE_URL;

export const getDisplayAd = async () => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const url = `${baseUrl}/api/ads/serve/display`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};