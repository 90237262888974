import React, { useState } from "react";
import { Button, Select, Col, Row, Switch, Space, Segmented } from "antd";
import {
  GlobalOutlined,
  KeyOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { genreMap } from "../enums/genres";
import { keysMap } from "../enums/keys";
import { timeSigMap } from "../enums/time-sig-map";

const segmentOptions = {
  ALL: "All Tracks",
  LEAD: "Lead",
  BASS: "Bass",
  DRUMS: "Drums",
};

const FilterSelectors = ({ onApplyFilter, filters }) => {
  const [selectedSegment, setSelectedSegment] = useState(segmentOptions.ALL);

  const genreOptions = [
    ...Object.keys(genreMap).map((key) => {
      return {
        value: key,
        label: genreMap[key],
      };
    }),
  ];

  const keyOptions = [
    ...Object.keys(keysMap).map((key) => {
      return {
        value: key,
        label: keysMap[key],
      };
    }),
  ];

  const timeSigOptions = [
    ...Object.keys(timeSigMap).map((key) => {
      return {
        value: key,
        label: timeSigMap[key],
      };
    }),
  ];

  const tonalityOptions = [
    {
      value: "major",
      label: "Major",
    },
    {
      value: "minor",
      label: "Minor",
    },
  ];

  const hasActiveFilters = Object.keys(filters).length > 0;
  const selectSize = "small";

  const onChangeFilter = (key, value) => {
    const updatedFilter = filters;
    if (value === (undefined || false)) {
      delete updatedFilter[key];
    } else {
      updatedFilter[key] = value;
    }
    onApplyFilter(updatedFilter);
  };

  const onClearFilter = () => {
    setSelectedSegment(segmentOptions.ALL);
    onApplyFilter({});
  };

  return (
    <>
      <Row gutter={[8, 8]} justify="center">
        <Col>
          <Select
            placeholder="Genre"
            defaultValue={filters?.genre}
            value={filters?.genre}
            onChange={(value, option) => onChangeFilter("genre", value)}
            allowClear
            size={selectSize}
            suffixIcon={<GlobalOutlined />}
            options={genreOptions}
            dropdownMatchSelectWidth={false}
          />
        </Col>
        <Col>
          <Select
            placeholder="Key"
            defaultValue={filters?.key}
            value={filters?.key}
            onChange={(value, option) => onChangeFilter("key", value)}
            allowClear
            size={selectSize}
            suffixIcon={<KeyOutlined />}
            options={keyOptions}
            dropdownMatchSelectWidth={false}
          />
        </Col>
        <Col>
          <Select
            placeholder="Tonality"
            defaultValue={filters?.tonality}
            value={filters?.tonality}
            onChange={(value, option) => onChangeFilter("tonality", value)}
            allowClear
            size={selectSize}
            suffixIcon={<KeyOutlined />}
            options={tonalityOptions}
            dropdownMatchSelectWidth={false}
          />
        </Col>
        <Col>
          <Select
            placeholder="Time Signature"
            defaultValue={filters?.time_sig}
            value={filters?.time_sig}
            onChange={(value, option) => onChangeFilter("time_sig", value)}
            allowClear
            size={selectSize}
            suffixIcon={<RetweetOutlined />}
            options={timeSigOptions}
            dropdownMatchSelectWidth={false}
          />
        </Col>
      </Row>
      {hasActiveFilters && (
        <>
          <br />
          <Row justify="center">
            <Col>
              <Button onClick={onClearFilter} size={selectSize} type="link">
                Clear Filters
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default FilterSelectors;
