import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  Col,
  Row,
  Space,
  Typography,
  Skeleton,
} from "antd";
import {
  ExportOutlined,
} from "@ant-design/icons";
import { getDisplayAd } from "../../../services/ads";
import styles from "./in-feed-ad.module.scss"

const InFeedAd = () => {
  const [displayAd, setDisplayAd] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadAd = async () => {
    setIsLoading(true);
    try {
      const adData = await getDisplayAd();
      setDisplayAd(adData);
    } catch {
      setDisplayAd(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadAd();
  }, []);

  if (!isLoading && !displayAd) return null;


  return (
    <Col span={24}>
      <Card size='small' actions={[
        <a href={displayAd?.action_url} target="__blank">
          <div>
            <Space>
              {displayAd?.action_text}
              <ExportOutlined />
            </Space>
          </div>
        </a>
      ]}>
        {isLoading ? (<Skeleton active />) : (
          <>
            <Row justify="space-between">
              <Col span={20}>
                <Space align="start" size="middle">
                  <Avatar
                    shape="square"
                    size="large"
                    src={displayAd.account.logo}
                  // onClick={() => navigate(`/musicians/${blog.user.id}`)}
                  >
                    {displayAd.account.name[0]}
                  </Avatar>
                  <Space direction="vertical" size="middle">
                    <div>
                      <Typography.Title level={5} style={{ marginBottom: 0 }}>
                        <Space>{displayAd.name}</Space>
                      </Typography.Title>

                      <Space
                        split={<Typography.Text type="secondary">•</Typography.Text>}
                      >
                        <>
                          <Typography.Text type="secondary">
                            <small>{displayAd.account.name}</small>
                          </Typography.Text>
                          <Typography.Text type="secondary">
                            <small>Advertisement</small>
                          </Typography.Text>
                        </>
                      </Space>
                    </div>
                  </Space>
                </Space>
              </Col>
              <Col>
                {/* TODO: action buttons for ads? */}
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={24}>
                <Typography.Text>{displayAd.long_description}</Typography.Text>
              </Col>
              {displayAd.photo && (
                <Col span={24}>
                  <img
                    className={styles["ad-card-photo"]}
                    src={displayAd.photo}
                    style={{ height: 200, width: "100%", objectFit: "cover" }}
                    alt={displayAd.name}
                  />
                </Col>
              )}
            </Row>
          </>
        )}
      </Card>
    </Col>
  );
};

export default InFeedAd;
