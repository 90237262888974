import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Popover, Button, notification, Space } from "antd";
import { UserAddOutlined, UserDeleteOutlined, DollarOutlined } from "@ant-design/icons";
import AuthContext from "../../contexts/auth-context";
import FeedContext from "../../contexts/feed-context";
import MusicPlayerContext from "../../contexts/music-player-context";
import { toggleFollowUser } from "../../services/users";
import ProfileHeroCard from "../cards/profile-hero-card";

const UserProfilePopover = ({ user, isFollowing, children }) => {
  const { token, tokenClaim } = useContext(AuthContext);
  const { feed, setFeed } = useContext(FeedContext);
  const { current, setCurrent } = useContext(MusicPlayerContext);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const { pathname } = useLocation();

  const handleFollowClick = async () => {
    try {
      await toggleFollowUser(token, user, isFollowing);

      const newFeed = feed.map((track) => {
        if (track.user.id === user.id) {
          return {
            ...track,
            in_following_feed: !isFollowing,
          };
        }
        return track;
      });

      const newCurrent =
        current && current.user.id === user.id
          ? { ...current, in_following_feed: !isFollowing }
          : null;

      if (newCurrent) setCurrent(newCurrent);
      setFeed(
        pathname !== "/jamtracks/following"
          ? newFeed
          : newFeed.filter((t) => t.in_following_feed)
      );
      notification.open({
        message: isFollowing ? "User Unfollowed" : "User Followed",
        description: `You ${isFollowing ? "have unfollowed" : "are now following"
          } ${user.username}.`,
        placement: "bottomRight",
      });
    } catch {
      console.log("Favorite not saved");
      // TODO: provide feedback to user
    }
  };

  const newContent = (
    <Space direction="vertical">
      <ProfileHeroCard user={user} hideActionButtons />
      {token && tokenClaim.username !== user.username && (
        <div style={{ paddingTop: 12 }}>
          <Button
            block
            onClick={handleFollowClick}
            icon={isFollowing ? <UserDeleteOutlined /> : <UserAddOutlined />}
            type="primary"
          >
            {isFollowing ? "Unfollow" : "Follow"}
          </Button>
        </div>
      )}
      {user.accepts_donations && (
        <a href={user.donations_url} target="_blank" rel="noreferrer">
          <Button block icon={<DollarOutlined />}>
            Say Thanks
          </Button>
        </a>
      )}
      <Button onClick={() => navigate(`/musicians/${user.id}`)} block type="link" size="small">
        <small>
          View Profile
        </small>
      </Button>
    </Space>
  );

  useEffect(() => {
    const handleScroll = () => {
      setOpen(false);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Popover
      open={open}
      onOpenChange={handleOpenChange}
      content={newContent}
      placement="bottom"
    >
      {children}
    </Popover>
  );
};

export default UserProfilePopover;
