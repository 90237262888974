import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "antd";

const GenreCard = ({ name, encodedName }) => {
  const navigate = useNavigate();
  return (
    <Card
      hoverable
      style={{
        backgroundColor: "rgb(255, 255, 255, 92%)",
        textAlign: "center",
        cursor: "pointer"
      }}

      onClick={() => navigate(`/jamtracks/recent?genre=${encodedName}`)}
    >
      <b>{name}</b>
    </Card>
  );
};

export default GenreCard;
