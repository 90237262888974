const baseUrl = process.env.REACT_APP_BASE_URL;

export const listTools = async (token, params = {}) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };

  const queryArray = [];
  for (const [key, value] of Object.entries(params)) {
    queryArray.push(`${key}=${value}`);
  }
  const queryString = queryArray.length ? "?" + queryArray.join("&") : "";

  const url = `${baseUrl}/api/tools/${queryString}`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const getTool = async (token, id) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };

  const url = `${baseUrl}/api/tools/${id}/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const toggleToolFavorite = async (token, tool) => {
  const data = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
    body: JSON.stringify({
      is_favorite: !tool.is_favorite,
    }),
  };

  const url = `${baseUrl}/api/tools/${tool.id}/favorite/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};
