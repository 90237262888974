import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Space,
  Avatar,
  Typography,
  Button,
  notification,
} from "antd";
import {
  UserAddOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { toggleFollowUser } from "../../services/users";
import { getDateString } from "../../utils";
import AuthContext from "../../contexts/auth-context";
import FeedContext from "../../contexts/feed-context";
import MusicPlayerContext from "../../contexts/music-player-context";
import defaultCoverImage from "../../media/images/default_profile_background.jpg";

const ProfileHeroCard = ({ user, hideActionButtons = false }) => {
  const {
    is_following: isFollowing,
    id,
    username,
    photo,
    cover_photo: coverPhoto,
    date_joined: dateJoined,
  } = user;
  const { isLoggedIn, tokenClaim, token } = useContext(AuthContext);
  const { feed, setFeed } = useContext(FeedContext);
  const { current, setCurrent } = useContext(MusicPlayerContext);

  const [follow, setFollow] = useState(isFollowing);

  const navigate = useNavigate();
  const isCurrentUsersProfile = isLoggedIn ? tokenClaim.user_id === id : false;

  const handleFollowClick = async () => {
    try {
      await toggleFollowUser(token, user, follow);

      const newFeed = feed.map((track) => {
        if (track.user.id === user.id) {
          return {
            ...track,
            in_following_feed: !follow,
          };
        }
        return track;
      });

      const newCurrent =
        current && current.user.id === user.id
          ? { ...current, in_following_feed: !follow }
          : null;

      if (newCurrent) setCurrent(newCurrent);
      setFeed(newFeed);

      notification.open({
        message: follow ? "User Unfollowed" : "User Followed",
        description: `You ${follow ? "have unfollowed" : "are now following"} ${
          user.username
        }.`,
        placement: "bottomRight",
      });

      setFollow((prevState) => !prevState);
    } catch {
      console.log("User not followed");
      // TODO: provide feedback to user
    }
  };

  return (
    <Card
      size="small"
      cover={
        <img
          alt="Test"
          style={{
            maxHeight: 100,
            objectFit: "cover",
          }}
          src={coverPhoto || defaultCoverImage}
        />
      }
    >
      <Row justify="space-between" align="bottom" gutter={[12, 12]}>
        <Col>
          <Avatar
            src={photo}
            icon={<UserOutlined />}
            shape="square"
            size={100}
            style={{
              marginTop: -80,
              border: "3px solid white",
              backgroundColor: "#bfbfbf",
            }}
          />
        </Col>
        <Col flex="auto">
          <Typography.Title
            level={4}
            style={{
              marginBottom: 0,
            }}
          >
            {username}
          </Typography.Title>
          <Typography.Text type="secondary">
            <small>
              <Space>{`Joined ${getDateString(dateJoined)}`}</Space>
            </small>
          </Typography.Text>
        </Col>
        {!hideActionButtons && (
          <>
            {isLoggedIn && !isCurrentUsersProfile && (
              <Col flex="auto">
                <Button
                  block
                  type={follow ? "default" : "primary"}
                  onClick={handleFollowClick}
                >
                  <Space>
                    {follow ? <UserDeleteOutlined /> : <UserAddOutlined />}

                    {follow ? "Unfollow" : "Follow"}
                  </Space>
                </Button>
              </Col>
            )}

            {isCurrentUsersProfile && (
              <Col flex="auto">
                <Button block onClick={() => navigate("/edit-profile")}>
                  Edit Profile
                </Button>
              </Col>
            )}
          </>
        )}
      </Row>
    </Card>
  );
};

export default ProfileHeroCard;
