import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Modal,
  Select,
  Slider,
  Upload,
  Button,
  Space,
  Spin,
  Alert,
  notification,
  List,
  Typography,
  Radio,
} from "antd";
import {
  FileAddOutlined,
  CheckCircleOutlined,
  GlobalOutlined,
  KeyOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { genreMap } from "../../enums/genres";
import { keysMap } from "../../enums/keys";
import { tonalityMap } from "../../enums/keys";
import { timeSigMap } from "../../enums/time-sig-map";
import { capitalizeWord, convertToBase64 } from "../../utils";
import { createJamtrack } from "../../services/jamtracks";
import AuthContext from "../../contexts/auth-context";
import FeedContext from "../../contexts/feed-context";
import TextArea from "antd/es/input/TextArea";

const genreOptions = Object.keys(genreMap).map((g) => {
  return {
    value: g,
    label: genreMap[g],
  };
});

const keyOptions = Object.keys(keysMap).map((k) => {
  return {
    value: k,
    label: keysMap[k],
  };
});

const tonalityOptions = Object.keys(tonalityMap).map((t) => {
  return {
    value: t,
    label: tonalityMap[t],
  };
});

const timeSigOptions = Object.keys(timeSigMap).map((s) => {
  return {
    value: timeSigMap[s],
    label: timeSigMap[s],
  };
});

const targetAudienceOptions = {
  LEAD: {
    label: "Lead",
    description:
      "This jam track is ideal for musicians playing lead instruments.",
  },
  BASS: {
    label: "Bass Players",
    description:
      "This jam track is made for bass players, meaning it does not contain a bass track",
  },
  DRUMS: {
    label: "Drummers",
    description:
      "This jam track is made for drummers, meaning it does not include a drum track.",
  },
};

const NewJamtrackModal = ({ isOpen, setIsOpen }) => {
  const initialState = {
    title: null,
    genre: null,
    key: null,
    tonality: null,
    time_sig: null,
    tempo: 120,
    track: null,
    no_drums: false,
    no_bass: false,
  };

  const { token } = useContext(AuthContext);
  const { feed, setFeed } = useContext(FeedContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [jamtrack, setJamtrack] = useState(initialState);
  const [targetAudience, setTargetAudience] = useState(
    targetAudienceOptions.LEAD.label
  );
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChangeState = (key, value) => {
    setJamtrack({
      ...jamtrack,
      [key]: value,
    });
  };

  const handleChangeTrack = async (value) => {
    // TODO: add in validation
    setErrorMessage(null);
    if (value.size > 7000000) {
      // 7 MB
      setErrorMessage("File must be smaller than 7 MB");
    } else {
      const newTrack = await convertToBase64(value);
      setJamtrack({
        ...jamtrack,
        track: newTrack,
      });
    }
  };

  const handleChangeTarget = (newTarget) => {
    const label = newTarget.target.value;
    setTargetAudience(newTarget.target.value);

    let noDrums;
    let noBass;

    if (label === targetAudienceOptions.LEAD.label) {
      noBass = false;
      noDrums = false;
    } else if (label === targetAudienceOptions.BASS.label) {
      noBass = true;
      noDrums = false;
    } else {
      noBass = false;
      noDrums = true;
    }

    setJamtrack({
      ...jamtrack,
      no_drums: noDrums,
      no_bass: noBass,
    });
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const fileUploadProps = {
    name: "track",
    accept: "audio/mpeg",
    maxCount: 1,
    beforeUpload: (file) => {
      handleChangeTrack(file);
      return false;
    },
    onRemove: () => {
      handleChangeState("track", null);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleSubmit = async (e) => {
    const {
      title,
      description,
      genre,
      key,
      tempo,
      tonality,
      time_sig,
      track,
      no_drums,
      no_bass,
    } = jamtrack;

    if (!track) {
      setErrorMessage(
        "You have not selected your mp3 file, or your file is too large."
      );
      return;
    }

    setIsLoading(true);
    setErrorMessage(null);

    try {
      const formattedJamtrack = {
        title,
        description,
        genre,
        tempo,
        key,
        tonality,
        time_sig,
        track,
        no_drums,
        no_bass,
      };
      const submittedJam = await createJamtrack(token, formattedJamtrack);

      setIsOpen(false);
      setJamtrack(initialState);
      form.resetFields();

      if (pathname === "/jamtracks/recent") {
        const updatedFeed = [submittedJam, ...feed];
        setFeed(updatedFeed);
      } else {
        navigate("/jamtracks/recent");
      }
      notification.open({
        message: "Jam Track Added",
        description: `${submittedJam.title} has been successfully uploaded. A chord transcription will be automatically added within the next dayss.`,
        placement: "bottomRight",
      });
    } catch (error) {
      const errors = JSON.parse(error.message);
      const errorJsx = (
        <>
          {Object.keys(errors).map((k) => {
            return (
              <div>
                <b>{capitalizeWord(k)}</b>
                <List
                  size="small"
                  dataSource={errors[k]}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </div>
            );
          })}
        </>
      );
      setErrorMessage(errorJsx);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        title="New Jam Track"
        open={isOpen}
        closable={false}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={initialState}
          onFinish={handleSubmit}
          requiredMark={false}
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: "Please input your title",
              },
            ]}
          >
            <Input
              value={jamtrack.title}
              onChange={(e) => handleChangeState("title", e.target.value)}
              placeholder="Insert title"
            />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
          >
            <TextArea
              value={jamtrack.description}
              onChange={(e) => handleChangeState("description", e.target.value)}
              placeholder="Add description (optional)"
              maxLength={500}
              showCount
            />
          </Form.Item>
          <Form.Item
            name="genre"
            label="Genre"
            rules={[
              {
                required: true,
                message: "Please input a genre!",
              },
            ]}
          >
            <Select
              value={jamtrack.genre}
              onChange={(e) => handleChangeState("genre", e)}
              placeholder="Select genre"
              options={genreOptions}
              suffixIcon={<GlobalOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="key"
            label="Key (note)"
            rules={[
              {
                required: true,
                message: "Please input a key!",
              },
            ]}
          >
            <Select
              value={jamtrack.key}
              onChange={(e) => handleChangeState("key", e)}
              placeholder="Select key"
              options={keyOptions}
              suffixIcon={<KeyOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="tonality"
            label="Key (tonality)"
            rules={[
              {
                required: true,
                message: "Please input a tonality!",
              },
            ]}
          >
            <Select
              value={jamtrack.tonality}
              onChange={(e) => handleChangeState("tonality", e)}
              placeholder="Select tonality"
              options={tonalityOptions}
              suffixIcon={<KeyOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="time_sig"
            label="Time Signature"
            rules={[
              {
                required: true,
                message: "Please input a time signature!",
              },
            ]}
          >
            <Select
              value={jamtrack.time_sig}
              onChange={(e) => handleChangeState("time_sig", e)}
              placeholder="Select time signature"
              options={timeSigOptions}
              suffixIcon={<RetweetOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="tempo"
            label="Tempo"
            rules={[
              {
                required: true,
                message: "Please input a tempo!",
              },
            ]}
          >
            <Slider
              value={jamtrack.tempo}
              onChange={(e) => handleChangeState("tempo", e)}
              min={0}
              max={300}
              tooltip={{
                formatter: (value) => `${value} BPM`,
              }}
            />
          </Form.Item>
          <Form.Item>
            <div>
              <Typography.Paragraph>Target Musicians</Typography.Paragraph>
            </div>
            <div>
              <Radio.Group onChange={handleChangeTarget} value={targetAudience}>
                <Space direction="vertical">
                  {Object.keys(targetAudienceOptions).map((ta) => {
                    return (
                      <Radio value={targetAudienceOptions[ta].label}>
                        <div>{targetAudienceOptions[ta].label}</div>
                        <Typography.Text type="secondary">
                          {targetAudienceOptions[ta].description}
                        </Typography.Text>
                      </Radio>
                    );
                  })}
                </Space>
              </Radio.Group>
            </div>
          </Form.Item>
          <Form.Item
            name="file"
            rules={[
              {
                required: true,
                message: "Please select a file!",
              },
            ]}
          >
            <Upload.Dragger {...fileUploadProps}>
              <p className="ant-upload-drag-icon">
                {jamtrack.track ? <CheckCircleOutlined /> : <FileAddOutlined />}
              </p>
              <p className="ant-upload-text">
                {jamtrack.track ? "File Selected" : "Select Your MP3 File"}
              </p>
              <p className="ant-upload-hint">
                {jamtrack.track
                  ? "Click here or drag another file to change it."
                  : "Click here or drag a file to this area."}
              </p>
            </Upload.Dragger>
          </Form.Item>
          {errorMessage && (
            <>
              <Alert message={errorMessage} type="error" />
              <br />
            </>
          )}
          <div style={{ textAlign: "center" }}>
            {isLoading ? (
              <Spin />
            ) : (
              <Space>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button type="primary" htmlType="submit">
                  Upload
                </Button>
              </Space>
            )}
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default NewJamtrackModal;
