import React, { useContext } from "react";
import { Avatar, Card, Col, Row, Space, Typography } from "antd";
import Markdown from "react-markdown";
import AuthContext from "../../../contexts/auth-context";
import styles from "./lesson-preview.module.scss"; //TODO rename blog to lesson
import { isValidYouTubeUrl } from "../../../utils";
import VideoPlayer from "../../../components/video-player/video-player";
import mediaItemTypes from "../../../enums/media-item-types";

const LessonPreview = ({ title, desc, content, videoSetting, videoYoutubeUrl }) => {
  const {
    tokenClaim: { username },
  } = useContext(AuthContext);
  return (
    <Card size="small">
      <Typography.Title level={3}>{title}</Typography.Title>
      <Row justify="space-between" align="middle">
        <Col>
          <div>
            <Space
              split={<Typography.Text type="secondary">•</Typography.Text>}
            >
              <Space>
                <Avatar />
                <Typography.Text type="secondary">{username}</Typography.Text>
              </Space>
              <Typography.Text type="secondary">
                Work in progress...
              </Typography.Text>
            </Space>
          </div>
        </Col>
      </Row>
      {videoSetting !== "no_video" && (
        <>
          <div>
            {videoYoutubeUrl && isValidYouTubeUrl(videoYoutubeUrl) && (
              <div style={{ marginTop: 10 }}>
                <VideoPlayer
                  src={videoYoutubeUrl}
                  mediaItem={{
                    title: title,
                    user: { username: "Work in Progress" },
                    type: mediaItemTypes.LESSON,
                  }}
                />
              </div>
            )}
          </div>
        </>
      )}
      <br />
      <div className={styles["content-container"]}>
        <Markdown className={styles["markdown-component"]}>{content}</Markdown>
      </div>
    </Card>
  );
};

export default LessonPreview;
