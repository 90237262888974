import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button, Tooltip } from "antd";
import { EyeFilled, EyeInvisibleFilled } from "@ant-design/icons";
import Typography from "antd/es/typography/Typography";
import { getTimeAgo } from "../../utils";

const notificationCategories = {
  ADMIN: "admin",
  WELCOME: "welcome",
  FOLLOWER: "follower",
  TRANSCRIPTION: "transcription",
  APP_APPROVED: "application_approved",
  COMMENT: "comment",
  EMPTY: "",
};

const NotificationCard = ({ notification, onClickSeenButton }) => {
  const navigate = useNavigate();

  const getCardTitle = () => {
    let title = null;
    switch (notification.category) {
      case notificationCategories.WELCOME:
        title = "Welcome to Jamtrackers";
        break;
      case notificationCategories.FOLLOWER:
        title = "New Follower";
        break;
      case notificationCategories.TRANSCRIPTION:
        title = "Transcription Completed";
        break;
      case notificationCategories.APP_APPROVED:
        title = "Creator Application Approved!";
        break;
      case notificationCategories.COMMENT:
        title = "New Comment";
        break;
      default:
        break;
    }

    return title;
  };

  const getCardActionButton = () => {
    if (notification.category === notificationCategories.WELCOME) {
      return [
        <a href="mailto:jamtrackers@gmail.com">
          <div>Contact Us</div>
        </a>,
      ];
    }

    if (notification.category === notificationCategories.TRANSCRIPTION) {
      return [
        <div onClick={() => navigate(`/transcribe/${notification.target_id}`)}>
          Edit Transcription
        </div>,
      ];
    }

    if (notification.category === notificationCategories.COMMENT) {
      return [
        <div onClick={() => navigate(`/${notification.target_id}`)}>
          View Jam Track
        </div>,
      ];
    }
    return null;
  };

  const getSeenButton = () => {
    return (
      <Tooltip title={notification.is_seen ? "Mark as new" : "Mark as seen"}>
        <Button type="text" onClick={() => onClickSeenButton(notification)}>
          <Typography.Text type="secondary">
            {notification.is_seen ? <EyeInvisibleFilled /> : <EyeFilled />}
          </Typography.Text>
        </Button>
      </Tooltip>
    );
  };

  return (
    <Card size="small" actions={getCardActionButton()}>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {getCardTitle()}
          </Typography.Title>
          <Typography.Paragraph>
            <small>{getTimeAgo(notification.created)}</small>
          </Typography.Paragraph>
        </Col>
        <Col>{getSeenButton()}</Col>
      </Row>
      <Row>
        <Col>{notification.message}</Col>
      </Row>
    </Card>
  );
};

export default NotificationCard;
