import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Input, Select, Empty, Skeleton } from "antd";
import {
  SearchOutlined,
} from "@ant-design/icons";
import FeedContext from "../../contexts/feed-context";
import AuthContext from "../../contexts/auth-context";
import { listJamtracks } from "../../services/jamtracks";
import { listBlogs } from "../../services/blogs";
import { listTools } from "../../services/tools";
import { listChordProgs } from "../../services/chord-progressions";
import SkeletonJamtrackCard from "../../components/cards/skeleton-jamtrack-card";
import JamtrackCard from "../../components/cards/jamtrack-card";
import BlogCard from "../../components/cards/blog-card/blog-card";
import ToolCard from "../../components/cards/tool-card/tool-card";
import ChordProgCard from "../../components/cards/chord-prog-card/chord-prog-card";

const SearchPage = () => {
  const { feed, setFeed } = useContext(FeedContext);
  const { token, setToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedResource, setSelectedResource] = useState("all");
  const [blogs, setBlogs] = useState([]);
  const [tools, setTools] = useState([]);
  const [chordProgs, setChordProgs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const searchResultsEmpty = feed.length === 0 && blogs.length === 0 && tools.length === 0;

  const loadResults = async () => {
    setIsLoading(true);
    try {
      const jamtrackResults = await listJamtracks(token, { search: searchTerm })
      const blogResults = await listBlogs(token, { search: searchTerm })
      const toolResults = await listTools(token, { search: searchTerm })
      const chordProgResults = await listChordProgs(token, { search: searchTerm })

      setFeed(jamtrackResults.results);
      setBlogs(blogResults.results);
      setTools(toolResults.results);
      setChordProgs(chordProgResults.results)
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const jamtrackResults = (
    <Row gutter={[12, 12]}>
      {feed.map((jt) => (
        <Col key={jt.id} span={24}>
          <JamtrackCard jamtrack={jt} />
        </Col>
      ))}
    </Row>
  )

  const blogResults = (
    <Row gutter={[12, 12]}>
      {blogs.map((b) => (
        <Col key={b.id} span={24}>
          <BlogCard blog={b} />
        </Col>
      ))}
    </Row>
  )

  const toolResults = (
    <Row gutter={[12, 12]}>
      {tools.map((t) => (
        <Col key={t.id} span={24}>
          <ToolCard tool={t} />
        </Col>
      ))}
    </Row>
  )

  const chordProgResults = (
    <Row gutter={[12, 12]}>
      {chordProgs.map((cp) => (
        <Col key={cp.id} span={24}>
          <ChordProgCard chordProg={cp} onDelete={() => console.log("implement")} />
        </Col>
      ))}
    </Row>
  )

  const concatenatedResults = [
    ...feed.map((jt) => ({ ...jt, type: "jamtrack" })),
    ...blogs.map((b) => ({ ...b, type: "blog" })),
    ...tools.map((t) => ({ ...t, type: "tool" })),
    ...chordProgs.map((cp) => ({ ...cp, type: "chordProg" })),
  ].sort((a, b) => new Date(b.created) - new Date(a.created));

  const allResults = (
    <Row gutter={[12, 12]}>
      {concatenatedResults.map((r) => {
        if (r.type === "jamtrack") {
          return (
            <Col span={24}>
              <JamtrackCard jamtrack={r} />
            </Col>
          )
        }
        if (r.type === "blog") {
          return (
            <Col span={24}>
              <BlogCard blog={r} />
            </Col>
          )
        }
        if (r.type === "tool") {
          return (
            <Col span={24}>
              <ToolCard tool={r} />
            </Col>
          )
        }
        if (r.type === "chordProg") {
          return (
            <Col span={24}>
              <ChordProgCard chordProg={r} onDelete={console.log("Gross")} />
            </Col>
          )
        }
      })}
    </Row>
  );

  const resourceResultsMapping = {
    all: allResults,
    jamtracks: jamtrackResults,
    blogs: blogResults,
    tools: toolResults,
    chordProgs: chordProgResults,
  }

  useEffect(() => {
    setIsLoading(true);
    const searchDebounce = setTimeout(
      async () => {
        await loadResults();
      },
      searchTerm ? 800 : 0,
    );

    return () => {
      clearTimeout(searchDebounce);
    };
  }, [searchTerm]);
  return (
    <>
      <Row justify={[12, 12]} gutter={[12, 12]}>
        <Col xs={24} sm={15}>
          <Input
            allowClear
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search Jam Tracks, Blogs, and Tools by Title"
            prefix={<SearchOutlined />}
            size="large"
          />
        </Col>
        <Col xs={24} sm={9}>
          <Select
            size="large"
            style={{ width: "100%" }}
            value={selectedResource}
            onChange={(e) => setSelectedResource(e)}
            options={
              [
                {
                  value: "all",
                  label: "All Resources",
                },
                {
                  value: "jamtracks",
                  label: "Jam Tracks",
                },
                {
                  value: "blogs",
                  label: "Blogs",
                },
                {
                  value: "tools",
                  label: "Tools",
                },
                {
                  value: "chordProgs",
                  label: "Chord Progressions",
                },
              ]
            }
          />
        </Col>
      </Row>
      <br />
      {isLoading && (
        <Row align="middle" justify="center" gutter={[12, 12]}>
          {[1, 2, 3, 4, 5].map((x) => (
            <Col key={x} span={24}>
              <SkeletonJamtrackCard />
            </Col>
          ))}
        </Row>
      )}
      {!isLoading && searchResultsEmpty && (
        <Row style={{ height: 200 }} align="middle" justify="center">
          <Col>
            <Empty description="No results were found that match the search term." image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </Col>
        </Row>
      )
      }
      {!isLoading && !searchResultsEmpty && (

        <Row gutter={[12, 12]}>
          <Col span={24}>
            {resourceResultsMapping[selectedResource]}
          </Col>
        </Row>
      )}
    </>
  )
}

export default SearchPage;