import React, { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Typography, Card, theme, Select, Button, Space } from "antd";
import Container from "../../components/layout/container/container";
import MusicPlayerContext from "../../contexts/music-player-context";
import { guitarChords, guitar } from "../../chords";
import { formatFromChordDb } from "../../utils";
import Chord from "@tombatossals/react-chords/lib/Chord";
import { getInitialParamsDict } from "../../utils";
import FooterLinks from "../../components/footer-links/footer-links";


const ChordSelectors = ({ note, onChangeNote, tonality, onChangeTonality }) => {
  const keyOptions = [
    ...Object.keys(guitarChords.chords).map((key) => {
      return {
        value: key,
        label: formatFromChordDb(key),
      };
    }),
  ];

  const tonalityOptions = note
    ? guitarChords.chords[note].map((suf) => {
      return {
        value: suf.suffix,
        label: formatFromChordDb(suf.suffix),
      };
    })
    : [];

  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} lg={12}>
        <Select
          style={{
            width: "100%",
          }}
          placeholder="Note"
          defaultValue={note}
          value={note}
          onChange={(value, option) => onChangeNote(value)}
          allowClear
          options={keyOptions}
        />
      </Col>
      <Col xs={24} lg={12}>
        <Select
          style={{
            width: "100%",
          }}
          placeholder="Tonality"
          defaultValue={tonality}
          value={tonality}
          onChange={(value, option) => onChangeTonality(value)}
          allowClear
          options={tonalityOptions}
          disabled={!note}
        />
      </Col>
    </Row>
  );
};

const ScaleExporer = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { pause } = useContext(MusicPlayerContext);
  pause(); // stop playing music when page loads

  const navigate = useNavigate();
  const location = useLocation();

  const initialParams = getInitialParamsDict(location.search);

  const [note, setNote] = useState(initialParams.note);
  const [tonality, setTonality] = useState(initialParams.tonality);

  const onChangeNote = (newNote) => {
    const newFilters = {
      note: newNote,
      tonality,
    };
    const newParamString = new URLSearchParams(newFilters).toString();
    const newUrl =
      location.pathname + (newParamString ? `?${newParamString}` : "");
    window.history.replaceState(null, "", newUrl);
    setNote(newNote);
  };

  const onChangeTonality = (newTonality) => {
    const newFilters = {
      note,
      tonality: newTonality,
    };
    const newParamString = new URLSearchParams(newFilters).toString();
    const newUrl =
      location.pathname + (newParamString ? `?${newParamString}` : "");
    window.history.replaceState(null, "", newUrl);
    setTonality(newTonality);
  };

  const handleClear = () => {
    setNote(null);
    setTonality(null);
    window.history.replaceState(null, "", location.pathname);
  };

  return (
    <Container>
      <div
        style={{
          padding: "12px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Row justify="center">
          <Col xs={24} md={18} lg={12}>
            <Card>
              <div style={{ textAlign: "center" }}>
                <Typography.Title
                  level={2}
                  style={{ color: colorPrimary }}
                  className="brand-title"
                >
                  Jamtrackers
                </Typography.Title>
              </div>
              <Typography.Title level={4}>Scale Explorer</Typography.Title>
              <Typography.Paragraph>
                The Scale Explorer allows you to see an interactive fretboard that maps out scales for any given note and tonality.
              </Typography.Paragraph>
              <ChordSelectors
                note={note}
                tonality={tonality}
                onChangeNote={onChangeNote}
                onChangeTonality={onChangeTonality}
              />
              <br />
              {/* <ChordPlacements note={note} tonality={tonality} /> */}
              <Row justify="center">
                <Col>
                  <Space>
                    {note && tonality && (
                      <Button onClick={handleClear}>Clear</Button>
                    )}
                    <Button type="primary" onClick={() => navigate("/")}>
                      Back to Home
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Card>
            <br />
            <FooterLinks />
          </Col>
        </Row>
      </div>
    </Container>
  )
};

export default ScaleExporer;