import React from "react";
import { Card, Row, Col, Button, Space } from "antd";
import {
  ArrowRightOutlined,
} from "@ant-design/icons";

const HeroHighlightCard = ({
  title,
  description,
  image,
  buttonText,
  onClickButton,
}) => {
  return (
    <Card
      size="small"
      style={{
        backgroundImage: `url(${image})`,
        height: 250,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Row style={{ height: 226 }} align="bottom">
        <Col xs={{ span: 24 }} sm={{ span: 20 }}>
          <Card
            size="small"
            bordered={false}
            style={{ backgroundColor: "rgb(255, 255, 255, 80%)" }}
          >
            <Card.Meta title={title} description={description} />
            {buttonText && (
              <>
                <br />
                <div style={{ textAlign: "right" }}>
                  <Button onClick={onClickButton} type="link" size="small">
                    <Space>
                      {buttonText}
                      <ArrowRightOutlined />
                    </Space>
                  </Button>
                </div>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default HeroHighlightCard;
