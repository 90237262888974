import React, { useState } from "react";

const FeedContext = React.createContext({
  feed: [],
  setFeed: () => {},
});

export const FeedContextProvider = (props) => {
  const [jamtrackFeed, setJamtrackFeed] = useState([]);

  const contextValue = {
    feed: jamtrackFeed,
    setFeed: setJamtrackFeed,
  };

  return (
    <FeedContext.Provider value={contextValue}>
      {props.children}
    </FeedContext.Provider>
  );
};

export default FeedContext;
