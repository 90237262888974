import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Typography, Row, Col, theme, Button } from "antd";

const ErrorPage = ({ }) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <div style={{ display: "block" }}>
        <Card>
          <Row justify="center">
            <Col>
              <Typography.Title
                level={2}
                style={{ color: colorPrimary }}
                className="brand-title"
              >
                Jamtrackers
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Title level={4}>Uh oh! Something went wrong...</Typography.Title>
              <Typography.Paragraph>
                An unexpected error occured. Click the button below for a fresh start.
              </Typography.Paragraph>
              <Button onClick={() => navigate("/")}>Reload Jamtrackers</Button>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  )
};

export default ErrorPage;