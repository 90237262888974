import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Dropdown, Modal, notification, Typography } from "antd";
import {
  MoreOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  DeleteOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import AuthContext from "../../contexts/auth-context";
import { toggleFollowUser } from "../../services/users";
import { deleteJamtrack } from "../../services/jamtracks";
import FeedContext from "../../contexts/feed-context";
import MusicPlayerContext from "../../contexts/music-player-context";

const JamtrackActionButtons = ({ jamtrack, size = "medium" }) => {
  const { token, tokenClaim, isLoggedIn } = useContext(AuthContext);
  const { feed, setFeed } = useContext(FeedContext);
  const { current, setCurrent } = useContext(MusicPlayerContext);
  const { queue, removeFromQueue } = useContext(MusicPlayerContext);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleFollowClick = async () => {
    try {
      await toggleFollowUser(token, jamtrack.user, jamtrack.in_following_feed);

      const newFeed = feed.map((track) => {
        if (track.user.id === jamtrack.user.id) {
          return {
            ...track,
            in_following_feed: !jamtrack.in_following_feed,
          };
        }
        return track;
      });

      const newCurrent =
        current && current.user.id === jamtrack.user.id
          ? { ...current, in_following_feed: !jamtrack.in_following_feed }
          : null;

      if (newCurrent) setCurrent(newCurrent);
      setFeed(
        pathname !== "/jamtracks/following"
          ? newFeed
          : newFeed.filter((t) => t.in_following_feed)
      );
      openNotification(
        jamtrack.in_following_feed ? "User Unfollowed" : "User Followed",
        `You ${jamtrack.in_following_feed ? "have unfollowed" : "are now following"
        } ${jamtrack.user.username}.`
      );
    } catch {
      console.log("Favorite not saved");
      // TODO: provide feedback to user
    }
  };

  const handleClickShare = () => {
    let hostname = window.location.hostname;
    if (hostname === "localhost") hostname = "localhost:3000";
    navigator.clipboard.writeText(hostname + `/jamtracks/${jamtrack.id}`);

    openNotification(
      "Copied to Clipboard",
      "A direct link to this jamtrack has been copied to your clipboard."
    );
  };

  const handleDelete = async () => {
    try {
      await deleteJamtrack(token, jamtrack);

      const newFeed = feed.filter((track) => track.id !== jamtrack.id);
      const trackInQueue =
        queue.filter((track) => track.id === jamtrack.id).length > 0;

      if (current && current.id === jamtrack.id) setCurrent(null);
      if (trackInQueue)
        removeFromQueue(queue.findIndex((track) => track.id === jamtrack.id));
      setFeed(newFeed);

      setShowDeleteModal(false);
      openNotification(
        "Jam Track Deleted",
        `You have successfully deleted ${jamtrack.title}.`
      );
    } catch (error) {
      console.log(error);
      // TODO: provide feedback to user
    }
  };

  const openNotification = (message, description) => {
    notification.open({
      message,
      description,
      placement: "bottomRight",
    });
  };

  const items = [
    {
      key: "Share",
      label: "Share",
      icon: <ShareAltOutlined />,
      onClick: handleClickShare,
    },
  ];

  if (isLoggedIn && jamtrack.user.username !== tokenClaim.username) {
    items.push({
      key: "follow",
      label:
        (jamtrack.in_following_feed ? "Unfollow" : "Follow") +
        " " +
        jamtrack.user.username,
      icon: jamtrack.in_following_feed ? (
        <UserDeleteOutlined />
      ) : (
        <UserAddOutlined />
      ),
      onClick: handleFollowClick,
    });
  } else if (isLoggedIn) {
    if (jamtrack.is_transcribed) {
      items.push({
        key: "transcribe",
        label: "Edit transcription",
        icon: <Typography.Text>B♭</Typography.Text>,
        onClick: () => navigate(`/transcribe/${jamtrack.id}`),
      });
    }
    items.push({
      key: "delete",
      label: "Delete jamtrack",
      icon: <DeleteOutlined />,
      danger: true,
      onClick: () => setShowDeleteModal(true),
    });
  }

  return (
    <>
      <Dropdown
        trigger="click"
        menu={{
          items,
        }}
      >
        <Button icon={<MoreOutlined />} type="text" size={size} />
      </Dropdown>
      <Modal
        title="Delete Jam Track"
        open={showDeleteModal}
        closable={false}
        onCancel={() => setShowDeleteModal(false)}
        onOk={handleDelete}
        okText="Delete"
        okType="danger"
      >
        Are you sure you want to delete {jamtrack.title}? It will not be
        possible to recover it.
      </Modal>
    </>
  );
};

export default JamtrackActionButtons;
