import guitarJSON from "./guitar.json";

export const guitarChords = guitarJSON;

export const guitar = {
  strings: 6,
  fretsOnChord: 4,
  name: "Guitar",
  keys: [],
  tunings: {
    standard: ["E", "A", "D", "G", "B", "E"],
  },
};
