import { Card, Skeleton } from "antd";
import { useContext } from "react";
import AuthContext from "../../contexts/auth-context";

const SkeletonJamtrackCard = () => {
  const { isLoggedIn } = useContext(AuthContext);

  const actions = [
    <Skeleton.Button key={1} size="small" active />,
    <Skeleton.Button key={2} size="small" active />,
  ];

  if (isLoggedIn)
    actions.push(
      <Skeleton.Button key={4} size="small" active />
    );
  return (
    <Card actions={actions}>
      <Skeleton avatar={{ shape: "square" }} active paragraph={{ rows: 0 }} />
      <Skeleton active paragraph={{ rows: 1 }} />
    </Card>
  );
};

export default SkeletonJamtrackCard;
