import React, { useContext } from "react";
import {
  Drawer,
  List,
  Avatar,
  Button,
  Typography,
  Divider,
  Row,
  Col,
  Card,
  Space,

} from "antd";
import {
  PlayCircleFilled,
  CloseCircleFilled,
  OrderedListOutlined,
} from "@ant-design/icons";
import MusicPlayerContext from "../../contexts/music-player-context";
import AudioControls from "../layout/audio-controls/audio-controls";
import TrackProgressSlider from "../layout/track-progress-slider";
import JamtrackCard from "../cards/jamtrack-card";
import mediaItemTypes from "../../enums/media-item-types";
import { useNavigate } from "react-router-dom";

const SimpleCard = ({ mediaItem }) => {
  const navigate = useNavigate();
  return (
    <Card size="small">
      <Row justify="space-between">
        <Col span={20}>
          <Space align="start" size="middle">
            <Avatar
              shape="square"
              size="large"
              src={mediaItem.user.photo}
              onClick={() => navigate(`/musicians/${mediaItem.user.id}`)}
            >
              {mediaItem.user.username[0]}
            </Avatar>
            <Space direction="vertical" size="middle">
              <div>
                <Typography.Title level={5} style={{ marginBottom: 0 }}>
                  {mediaItem.title}
                </Typography.Title>
                <Space
                  split={<Typography.Text type="secondary">•</Typography.Text>}
                >
                  <Typography.Link type="secondary">
                    <small>{mediaItem.user.username}</small>
                  </Typography.Link>
                </Space>
              </div>
            </Space>
          </Space>
        </Col>
      </Row>
    </Card>
  )
}

const NowPlaying = ({ current }) => {

  return (
    <>
      <Typography.Title level={5}>Now Playing</Typography.Title>
      {current?.type === mediaItemTypes.JAMTRACK ? (
        <JamtrackCard jamtrack={current} />
      ) : (
        <SimpleCard mediaItem={current} />
      )}
      <br />
      <Row justify="center">
        <Col>
          <AudioControls />
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24}>
          <TrackProgressSlider />
        </Col>
      </Row>
      <br />
      <Divider style={{ marginTop: 0 }} />
    </>
  );
};

const QueueDrawer = ({ isOpen, onClose }) => {
  const musicPlayerCtx = useContext(MusicPlayerContext);
  const { current } = musicPlayerCtx;

  const isSmallScreen = window.innerWidth <= 500;

  return (
    <>
      <Drawer
        width={isSmallScreen ? window.innerWidth : 500}
        open={isOpen}
        onClose={() => onClose(false)}
      >
        {current && <NowPlaying current={current} />}
        <Row justify="space-between">
          <Col>
            <Typography.Title level={5}>Queue</Typography.Title>
          </Col>
          {musicPlayerCtx.queue.length > 0 && (
            <Col>
              <Button onClick={() => musicPlayerCtx.resetQueue()} size="small">
                Clear Queue
              </Button>
            </Col>
          )}
        </Row>
        {musicPlayerCtx.queue.length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={musicPlayerCtx.queue.map((jt) => ({ ...jt, "mock_key": Math.random() }))}
            rowKey={"mock_key"}
            renderItem={(item, index) => (
              <List.Item
                actions={[
                  <Button
                    onClick={() => musicPlayerCtx.startNew({ ...item, type: mediaItemTypes.JAMTRACK })}
                    size="small"
                    type="link"
                  >
                    <PlayCircleFilled />
                  </Button>,
                  <Button
                    onClick={() => musicPlayerCtx.removeFromQueue(index)}
                    size="small"
                    type="link"
                  >
                    <CloseCircleFilled />
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  avatar={
                    <Avatar shape="square" src={item.user.photo}>
                      {item.user.username[0]}
                    </Avatar>
                  }
                  title={item.title}
                  description={<small>{item.user.username}</small>}
                />
              </List.Item>
            )}
          />
        ) : (
          <Row justify="center">
            <Col style={{ textAlign: "center" }}>
              <Typography.Title level={5} type="secondary">
                Your queue is empty.
              </Typography.Title>
              <Typography.Paragraph type="secondary">
                Press the{" "}
                <code>
                  <OrderedListOutlined />
                </code>{" "}
                button to add songs.
              </Typography.Paragraph>
            </Col>
          </Row>
        )}
      </Drawer>
    </>
  );
};

export default QueueDrawer;
