// mapping from the value saved in jamtrackers api -> what tonal js expects

export const tonalMap = {
    a: "A",
    "a_sharp/b_flat": "A#",
    b: "B",
    c: "C",
    "c_shard/d_flat": "C#",
    d: "D",
    "d_sharp/e_flat": "D#",
    e: "E",
    f: "F",
    "f_shard/g_flat": "F#",
    g: "G",
    "g_sharp/a_flat": "G#",
};