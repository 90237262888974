const baseUrl = process.env.REACT_APP_BASE_URL;

// COURSES
export const listCourses = async (token, params = {}) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };

  const queryArray = [];
  for (const [key, value] of Object.entries(params)) {
    queryArray.push(`${key}=${value}`);
  }
  const queryString = queryArray.length ? "?" + queryArray.join("&") : "";

  const url = `${baseUrl}/api/courses${queryString}`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const getCourse = async (token, id) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };

  const url = `${baseUrl}/api/courses/${id}/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const updateCourse = async (token, courseId, updatedFields) => {
  const data = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
    body: JSON.stringify(updatedFields),
  };

  const url = `${baseUrl}/api/courses/${courseId}/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const createCourse = async (token, course) => {
  const data = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(course),
  };

  const url = `${baseUrl}/api/courses/`;
  const response = await fetch(url, data);
  if (response.status !== 201) {
    const json = await response.json();
    throw Error(JSON.stringify(json));
  }
  return response.json();
};

export const deleteCourse = async (token, course) => {
  const data = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const url = `${baseUrl}/api/courses/${course.id}/`;
  const response = await fetch(url, data);
  if (response.status !== 204) {
    throw Error;
  }
  return response;
};

export const toggleCourseFavorite = async (token, course) => {
  const data = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
    body: JSON.stringify({
      is_favorite: !course.is_favorite,
    }),
  };

  const url = `${baseUrl}/api/courses/${course.id}/favorite/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

// LESSONS
export const getLesson = async (token, id) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };

  const url = `${baseUrl}/api/courses/lessons/${id}/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const updateLesson = async (token, lessonId, updatedFields) => {
  const data = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
    body: JSON.stringify(updatedFields),
  };

  const url = `${baseUrl}/api/courses/lessons/${lessonId}/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const createLesson = async (token, lesson) => {
  const data = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(lesson),
  };

  const url = `${baseUrl}/api/courses/lessons/`;
  const response = await fetch(url, data);
  if (response.status !== 201) {
    const json = await response.json();
    throw Error(JSON.stringify(json));
  }
  return response.json();
};

export const toggleLessonComplete = async (token, lesson) => {
  const data = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
    body: JSON.stringify({
      is_completed: !lesson.is_completed,
    }),
  };

  const url = `${baseUrl}/api/courses/lessons/${lesson.id}/complete/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const deleteLesson = async (token, lesson) => {
  const data = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const url = `${baseUrl}/api/courses/lessons/${lesson.id}/`;
  const response = await fetch(url, data);
  if (response.status !== 204) {
    throw Error;
  }
  return response;
};