export const keysMap = {
  a: "A",
  "a_sharp/b_flat": "A♯ / B♭",
  b: "B",
  c: "C",
  "c_shard/d_flat": "C♯ / D♭",
  d: "D",
  "d_sharp/e_flat": "D♯ / E♭",
  e: "E",
  f: "F",
  "f_shard/g_flat": "F♯ / G♭",
  g: "G",
  "g_sharp/a_flat": "G♯ / A♭",
};

export const tonalityMap = {
  major: "Major",
  minor: "Minor",
};
