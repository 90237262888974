import React, { useContext, useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Card,
  Affix,
  Space,
  Skeleton,
  Typography,
  Tooltip,
  Avatar,
  Button,
  Alert,
  notification,
  FloatButton,
  Dropdown,
} from "antd";
import {
  InfoCircleOutlined,
  LoginOutlined,
  NotificationOutlined,
  UserOutlined,
  MinusOutlined,
  CustomerServiceOutlined,
  CommentOutlined,
  ReadOutlined,
  PlusOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import Container from "../container/container";
import { useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../../../contexts/auth-context";
import { listChordsOfTheDay } from "../../../services/chords";
import { guitarChords, guitar } from "../../../chords";
import Chord from "@tombatossals/react-chords/lib/Chord";
import FooterLinks from "../../footer-links/footer-links";
import { retrieveUser } from "../../../services/users";
import { listTools, toggleToolFavorite } from "../../../services/tools";
import NewJamtrackModal from "../../new-jamtrack-modal/new-jamtrack-modal";
import defaultCoverImage from "../../../media/images/default_profile_background.jpg";
import styles from "./content-container.module.scss";

const { Content } = Layout;

const HelpIcon = ({ infoText }) => {
  return (
    <Typography.Text type="secondary">
      <Tooltip title={infoText}>
        <InfoCircleOutlined />
      </Tooltip>
    </Typography.Text>
  );
};

const LeftAffixWrapper = ({ children, onSetIsAffixed }) => {
  return (
    <Col xs={{ span: 0 }} md={{ span: 24 }}>
      <Affix
        offsetTop={76} // header + 12px margin
        onChange={(affixed) => onSetIsAffixed(affixed)}
      >
        {children}
      </Affix>
    </Col>
  );
};

const LeftSideContent = () => {
  const navigate = useNavigate();
  const {
    isLoggedIn,
    token,
    tokenClaim: { user_id: userId, is_creator: isCreator, username },
    unreadNotifCount,
    getUnreadNotifCount,
    toolbox,
    setToolbox,
  } = useContext(AuthContext);

  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [isAffixed, setIsAffixed] = useState(false);
  const [floatButtonOpen, setFloatButtonOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const loadData = async () => {
    setIsLoading(true);
    setHasError(false);

    try {
      if (token) getUnreadNotifCount();
      if (isCreator) {
        const userResult = await retrieveUser(token, userId);
        setUserProfile(userResult);
      }
      const { results: tools } = await listTools(token, { is_favorite: 1 });
      setToolbox(tools);
    } catch (error) {
      setHasError(true);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRemoveTool = async (tool) => {
    await toggleToolFavorite(token, tool);
    setToolbox(toolbox.filter((t) => t.id !== tool.id));
    notification.open({
      message: "Tool Removed",
      description: `${tool.title} has been removed from your toolbox.`,
      placement: "bottomRight",
    });
  };

  const additionalFloatButtons = isCreator
    ? [
      {
        action: () => {
          navigate("/courses/new");
          setFloatButtonOpen(false);
        },
        icon: <ReadOutlined />,
      },
      {
        action: () => {
          setIsNewModalOpen(true);
          setFloatButtonOpen(false);
        },
        path: "",
        icon: <CustomerServiceOutlined />,
      },
      {
        action: () => {
          navigate("/chord-progressions/new");
          setFloatButtonOpen(false);
        },
        path: "",
        icon: <AppstoreOutlined />,
      },
      {
        action: () => {
          navigate("/blogs/new");
          setFloatButtonOpen(false);
        },
        icon: <CommentOutlined />,
      },
    ]
    : [];

  const handleMenuClick = (e) => {
    if (e.key === "jamtrack") setIsNewModalOpen(true);
    if (e.key === "course") navigate("/courses/new");
    if (e.key === "blog") navigate("/blogs/new");
    if (e.key === "chord-progression") navigate("/chord-progressions/new");
  };

  const createMenuItems = [
    {
      label: "Course",
      key: "course",
      icon: <ReadOutlined />,
    },
    {
      label: "Jam Track",
      key: "jamtrack",
      icon: <CustomerServiceOutlined />,
    },
    {
      label: "Chord Progression",
      key: "chord-progression",
      icon: <AppstoreOutlined />,
    },
    {
      label: "Blog",
      key: "blog",
      icon: <CommentOutlined />,
    },
  ];

  const createMenuProps = {
    items: createMenuItems,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const interval = setInterval(getUnreadNotifCount, 100000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (isLoading) {
    return (
      <Card>
        <Skeleton active></Skeleton>
      </Card>
    );
  }

  return (
    <Row>
      {isLoggedIn && isCreator && (
        <Col sm={{ span: 24 }} md={{ span: 0 }}>
          <FloatButton.Group
            open={floatButtonOpen}
            trigger="click"
            onOpenChange={setFloatButtonOpen}
            style={{
              right: 20,
            }}
            icon={<PlusOutlined />}
            type="primary"
          >
            {additionalFloatButtons.map((b) => (
              <FloatButton onClick={b.action} icon={b.icon} />
            ))}
          </FloatButton.Group>
        </Col>
      )}
      <LeftAffixWrapper onSetIsAffixed={setIsAffixed}>
        <Row gutter={[0, 12]} justify="center">
          {!isLoggedIn && (
            <Col span={24}>
              <Card
                title="Login to Jamtrackers"
                size="small"
                actions={[
                  <div onClick={() => navigate("/register")}>Create Free Account</div>,
                ]}
              >
                <Card.Meta description="Log in to access favorited tracks, tools, and musicians." />
                <br />
                <Row>
                  <Col span={24}>
                    <Button
                      type="primary"
                      size="large"
                      block
                      onClick={() => navigate("/login")}
                    >
                      <Space>
                        <LoginOutlined />
                        <div>Login</div>
                      </Space>
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
          {userProfile && isCreator && isLoggedIn && (
            <Col span={24}>
              <Card
                size="small"
                cover={
                  <img
                    src={userProfile.cover_photo || defaultCoverImage}
                    alt={userProfile.username}
                    style={{
                      maxHeight: 75,
                      objectFit: "cover",
                    }}
                  />
                }
                actions={[
                  <div onClick={() => navigate("/edit-profile")}>
                    Edit Profile
                  </div>,
                ]}
              >
                <Row justify="space-between" align="bottom" gutter={[12, 12]}>
                  <Col>
                    <Avatar
                      size={80}
                      shape="square"
                      src={userProfile.photo}
                      icon={<UserOutlined />}
                      style={{
                        marginTop: -65,
                        border: "3px solid white",
                        backgroundColor: "#bfbfbf",
                      }}
                    />
                  </Col>
                  <Col flex="auto">
                    <Typography.Title
                      className={styles["username"]}
                      level={5}
                      onClick={() => navigate(`/musicians/${userId}`)}
                      style={{ marginBottom: 0 }}
                    >
                      {userProfile.username}
                    </Typography.Title>
                  </Col>
                </Row>
                <div style={{ marginTop: 8, marginBottom: 12 }}>
                  <Row justify="space-between">
                    <Col>
                      <b>
                        <small>Jam Tracks</small>
                      </b>
                    </Col>
                    <Col>
                      <small>{userProfile.jamtrack_count}</small>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>
                      <b>
                        <small>Blogs</small>
                      </b>
                    </Col>
                    <Col>
                      <small>{userProfile.blog_count}</small>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>
                      <b>
                        <small>Followers</small>
                      </b>
                    </Col>
                    <Col>
                      <small>{userProfile.follower_count}</small>
                    </Col>
                  </Row>
                </div>
                <Row gutter={[12, 12]}>
                  {unreadNotifCount > 0 && (
                    <Col span={24}>
                      <Alert
                        showIcon
                        icon={<NotificationOutlined />}
                        message={
                          <b>
                            {unreadNotifCount} New{" "}
                            {unreadNotifCount === 1
                              ? "Notification"
                              : "Notifications"}
                          </b>
                        }
                        action={
                          <Button
                            type="primary"
                            ghost
                            size="small"
                            onClick={() => navigate("/notifications")}
                          >
                            View
                          </Button>
                        }
                      />
                    </Col>
                  )}
                  <Col span={24}>
                    <Dropdown menu={createMenuProps}>
                      <Button type="primary" size="large" block>
                        <Space>
                          Create New
                          <PlusOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
          {isLoggedIn && !isCreator && (
            <Col span={24}>
              <Card size="small">
                <Typography.Text level={5}>
                  <b>Welcome, {username}!</b>
                </Typography.Text>
                {unreadNotifCount > 0 && (
                  <Alert
                    style={{ marginTop: 10 }}
                    showIcon
                    icon={<NotificationOutlined />}
                    message={
                      <b>
                        {unreadNotifCount} New{" "}
                        {unreadNotifCount === 1
                          ? "Notification"
                          : "Notifications"}
                      </b>
                    }
                    action={
                      <Button
                        type="primary"
                        ghost
                        size="small"
                        onClick={() => navigate("/notifications")}
                      >
                        View
                      </Button>
                    }
                  />
                )}
              </Card>
            </Col>
          )}
          {isLoggedIn && (
            <Col span={24}>
              <Card
                title="My Toolbox"
                size="small"
                actions={[
                  <div onClick={() => navigate("/tools")}>Browse Tools</div>,
                ]}
                extra={
                  <HelpIcon infoText="Save your favorite tools for easy access later on." />
                }
              >
                {toolbox.length === 0 && (
                  <Row justify="center">
                    <Col span={24}>
                      <Typography.Text type="secondary">
                        Your toolbox is empty. Try filling it up with some
                        tools.
                      </Typography.Text>
                    </Col>
                  </Row>
                )}
                {toolbox.map((t) => (
                  <Row justify="space-between" style={{ padding: 3 }}>
                    <Col>
                      <a href={t.key} target="_blank" rel="noreferrer">
                        {t.title}
                      </a>
                    </Col>
                    <Col>
                      <Button
                        size="small"
                        type="text"
                        onClick={() => onRemoveTool(t)}
                      >
                        <MinusOutlined />
                      </Button>
                    </Col>
                  </Row>
                ))}
              </Card>
            </Col>
          )}
        </Row>
      </LeftAffixWrapper>
      <NewJamtrackModal isOpen={isNewModalOpen} setIsOpen={setIsNewModalOpen} />
    </Row>
  );
};

const RightSideContent = () => {
  const { token, isLoggedIn, tokenClaim: { is_creator: isCreator } } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [chordOfTheDay, setChordOfTheDay] = useState(null);

  const loadData = async () => {
    setIsLoading(true);
    setHasError(false);

    try {
      const dayChords = await listChordsOfTheDay(token, { limit: 1 });
      if (dayChords.results.length > 0) {
        const chord = dayChords.results[0];
        const identifiedChord = chord
          ? guitarChords.chords[chord.key].find(
            (c) => c.suffix === chord.suffix
          ).positions[chord.position]
          : false;

        const completeChordData = {
          chord: identifiedChord,
          key: chord.key,
          tonality: chord.suffix,
        };
        setChordOfTheDay(identifiedChord ? completeChordData : null);
      }
    } catch (error) {
      setHasError(true);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading) {
    return (
      <Row gutter={[0, 12]} justify="center">
        <Col span={24}>
          <Card>
            <Skeleton active title loading={isLoading}></Skeleton>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[0, 12]} justify="center">
      {chordOfTheDay && location.pathname !== "/tools/chord-of-the-day" && (
        <Col xs={0} md={24}>
          <Card
            size="small"
            title="Chord of the Day"
            extra={
              <HelpIcon infoText="Each day we provide you with a fresh new chord to learn." />
            }
            actions={[
              <div onClick={() => navigate("/tools/chord-explorer")}>
                Explore Chords
              </div>,
            ]}
          >
            <Chord chord={chordOfTheDay.chord} instrument={guitar} />
            <div style={{ textAlign: "center" }}>
              <Typography.Text>
                <b>{chordOfTheDay.key + " " + chordOfTheDay.tonality}</b>
              </Typography.Text>
            </div>
          </Card>
        </Col>
      )}

      {!isLoggedIn && (
        <Col xs={24} md={0}>
          <Card
            size="small"
            actions={[
              <div onClick={() => navigate("/register")}>Create Free Account</div>,
            ]}
          >
            <Typography.Title level={4}>
              Login to Jamtrackers
            </Typography.Title>
            <Typography.Paragraph>
              Log in to access favorited content. Don't have an account? Create one for free.
            </Typography.Paragraph>
            <br />
            <Row>
              <Col span={24}>
                <Button
                  type="primary"
                  size="large"
                  block
                  onClick={() => navigate("/login")}
                >
                  <Space>
                    <LoginOutlined />
                    <div>Login</div>
                  </Space>
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      )}
      <Col>
        <div style={{ padding: "0px 20px" }}>
          <FooterLinks />
        </div>
      </Col>
    </Row>
  );
};

const ContentContainer = ({ children }) => {
  return (
    <Container>
      <Content
        style={{
          margin: "12px 0px",
          overflow: "initial",
          minHeight: "100vh", // TODO calcuate height with header
        }}
      >
        <Row gutter={[12, 12]} justify="center">
          <Col
            xs={{ span: 24, order: 2 }}
            md={{ span: 9, order: 1 }}
            lg={{ span: 6, order: 1 }}
            xl={{ span: 6, order: 1 }}
          >
            <LeftSideContent />
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            md={{ span: 15, order: 2 }}
            lg={{ span: 12, order: 2 }}
            xl={{ span: 12, order: 2 }}
          >
            {children}
          </Col>
          <Col
            xs={{ span: 24, order: 3 }}
            md={{ span: 0, order: 0 }}
            lg={{ span: 6, order: 3 }}
            xl={{ span: 6, order: 3 }}
          >
            <RightSideContent />
          </Col>
        </Row>
      </Content>
    </Container>
  );
};

export default ContentContainer;
