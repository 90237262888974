const baseUrl = process.env.REACT_APP_BASE_URL;

export const listCreatorApplications = async (token, params = {}) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const queryArray = [];
  for (const [key, value] of Object.entries(params)) {
    queryArray.push(`${key}=${value}`);
  }
  const queryString = queryArray.length ? "?" + queryArray.join("&") : "";

  const url = `${baseUrl}/api/moderation/creators${queryString}`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const createCreatorApplication = async (token, body) => {
  const data = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  };

  const url = `${baseUrl}/api/moderation/creators/`;
  const response = await fetch(url, data);
  if (response.status !== 201) {
    const json = await response.json();
    throw Error(JSON.stringify(json));
  }
  return response.json();
};
