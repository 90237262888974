const GrayIconSvg = () => {
  return (
    <svg
      zoomAndPan="magnify"
      viewBox="0 0 375 374.999991"
      height="35"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="7d97ca001a">
          <path
            d="M 4.523438 166 L 25 166 L 25 209 L 4.523438 209 Z M 4.523438 166 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="186959b5d0">
          <path
            d="M 142 15.046875 L 164 15.046875 L 164 360 L 142 360 Z M 142 15.046875 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="6670bc5188">
          <path
            d="M 350 166 L 370.523438 166 L 370.523438 209 L 350 209 Z M 350 166 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#7d97ca001a)">
        <path
          fill="#bfbfbf"
          d="M 14.691406 166.472656 C 9.074219 166.472656 4.523438 171.027344 4.523438 176.640625 L 4.523438 198.402344 C 4.523438 204.019531 9.074219 208.570312 14.691406 208.570312 C 20.304688 208.570312 24.855469 204.019531 24.855469 198.402344 L 24.855469 176.640625 C 24.859375 171.027344 20.304688 166.472656 14.691406 166.472656 Z M 14.691406 166.472656 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#bfbfbf"
        d="M 49.257812 137.511719 C 43.640625 137.511719 39.089844 142.0625 39.089844 147.679688 L 39.089844 227.367188 C 39.089844 232.980469 43.640625 237.53125 49.257812 237.53125 C 54.875 237.53125 59.425781 232.984375 59.425781 227.367188 L 59.425781 147.679688 C 59.425781 142.0625 54.875 137.511719 49.257812 137.511719 Z M 49.257812 137.511719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#bfbfbf"
        d="M 83.824219 85.542969 C 78.207031 85.542969 73.65625 90.09375 73.65625 95.710938 L 73.65625 279.335938 C 73.65625 284.953125 78.207031 289.503906 83.824219 289.503906 C 89.441406 289.503906 93.988281 284.953125 93.988281 279.335938 L 93.988281 95.710938 C 93.988281 90.09375 89.441406 85.542969 83.824219 85.542969 Z M 83.824219 85.542969 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#bfbfbf"
        d="M 118.390625 129.390625 C 112.773438 129.390625 108.222656 133.941406 108.222656 139.558594 L 108.222656 235.488281 C 108.222656 241.105469 112.773438 245.65625 118.390625 245.65625 C 124.003906 245.65625 128.554688 241.105469 128.554688 235.488281 L 128.554688 139.558594 C 128.558594 133.941406 124.003906 129.390625 118.390625 129.390625 Z M 118.390625 129.390625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#186959b5d0)">
        <path
          fill="#bfbfbf"
          d="M 152.957031 15.199219 C 147.34375 15.199219 142.792969 19.75 142.792969 25.367188 L 142.792969 349.679688 C 142.792969 355.292969 147.34375 359.84375 152.957031 359.84375 C 158.574219 359.84375 163.125 355.292969 163.125 349.679688 L 163.125 25.367188 C 163.125 19.75 158.574219 15.199219 152.957031 15.199219 Z M 152.957031 15.199219 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#bfbfbf"
        d="M 187.523438 60.675781 C 181.910156 60.675781 177.359375 65.226562 177.359375 70.839844 L 177.359375 304.203125 C 177.359375 309.820312 181.90625 314.371094 187.523438 314.371094 C 193.140625 314.371094 197.691406 309.820312 197.691406 304.203125 L 197.691406 70.839844 C 197.691406 65.226562 193.140625 60.675781 187.523438 60.675781 Z M 187.523438 60.675781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#bfbfbf"
        d="M 222.089844 137.171875 C 216.472656 137.171875 211.925781 141.722656 211.925781 147.339844 L 211.925781 227.707031 C 211.925781 233.320312 216.472656 237.875 222.089844 237.875 C 227.707031 237.875 232.257812 233.320312 232.257812 227.707031 L 232.257812 147.339844 C 232.257812 141.722656 227.707031 137.171875 222.089844 137.171875 Z M 222.089844 137.171875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#bfbfbf"
        d="M 256.65625 48.171875 C 251.042969 48.171875 246.492188 52.722656 246.492188 58.339844 L 246.492188 316.703125 C 246.492188 322.320312 251.042969 326.871094 256.65625 326.871094 C 262.273438 326.871094 266.824219 322.320312 266.824219 316.703125 L 266.824219 58.339844 C 266.824219 52.726562 262.273438 48.171875 256.65625 48.171875 Z M 256.65625 48.171875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#bfbfbf"
        d="M 291.222656 87.96875 C 285.609375 87.96875 281.058594 92.519531 281.058594 98.136719 L 281.058594 276.90625 C 281.058594 282.523438 285.609375 287.074219 291.222656 287.074219 C 296.839844 287.074219 301.390625 282.523438 301.390625 276.90625 L 301.390625 98.136719 C 301.390625 92.523438 296.839844 87.96875 291.222656 87.96875 Z M 291.222656 87.96875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill="#bfbfbf"
        d="M 325.789062 148.207031 C 320.175781 148.207031 315.625 152.757812 315.625 158.375 L 315.625 216.667969 C 315.625 222.285156 320.175781 226.835938 325.789062 226.835938 C 331.40625 226.835938 335.957031 222.285156 335.957031 216.667969 L 335.957031 158.375 C 335.957031 152.761719 331.40625 148.207031 325.789062 148.207031 Z M 325.789062 148.207031 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#6670bc5188)">
        <path
          fill="#bfbfbf"
          d="M 360.359375 166.472656 C 354.742188 166.472656 350.191406 171.027344 350.191406 176.640625 L 350.191406 198.402344 C 350.191406 204.019531 354.742188 208.570312 360.359375 208.570312 C 365.972656 208.570312 370.523438 204.019531 370.523438 198.402344 L 370.523438 176.640625 C 370.523438 171.027344 365.972656 166.472656 360.359375 166.472656 Z M 360.359375 166.472656 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  );
};

export default GrayIconSvg;
