import React, { useState } from "react";
import { List, Button, Collapse, Modal, Space, Select, Tag } from "antd";
import { convertToMinutes, formatFromChordDb } from "../../utils";

const TranscriptionList = ({ transcription, onDeleteChord, onUpdateChord }) => {
  const [markerModalVisible, setMarkerModalVisible] = useState(false);
  const [markerTime, setMarkerTime] = useState(null);
  const [markerAction, setMarkerAction] = useState(null);

  const markerOptions = [
    {
      value: "chorus",
      label: "chorus",
    },
    {
      value: "verse",
      label: "verse",
    },
    {
      value: "bridge",
      label: "bridge",
    },
  ];

  const handleSaveMarker = () => {
    const updatedChordData = transcription[markerTime];
    if (markerAction) updatedChordData["marker"] = markerAction;
    onUpdateChord(markerTime, updatedChordData);
    setMarkerModalVisible(false);
  };

  const handleRemoveMarker = () => {
    const updatedChordData = transcription[markerTime];
    updatedChordData.marker = null;
    onUpdateChord(markerTime, updatedChordData);
    setMarkerModalVisible(false);
  };

  const handleAddMarkerClick = (item) => {
    setMarkerTime(item);
    setMarkerAction(transcription[item]?.marker || null);
    setMarkerModalVisible(true);
  };

  return (
    <>
      <Collapse size="large">
        <Collapse.Panel header="View Transcription">
          <List
            dataSource={Object.keys(transcription).sort((a, b) => a - b)}
            renderItem={(item, index) => (
              <List.Item
                key={item.id}
                actions={[
                  <Button
                    type="link"
                    onClick={() => handleAddMarkerClick(item)}
                  >
                    {transcription[item]?.marker ? "Edit" : "Add"} Marker
                  </Button>,
                  <Button
                    onClick={() => onDeleteChord(item)}
                    size="small"
                    type="link"
                  >
                    Remove
                  </Button>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <Space>
                      {convertToMinutes(item)}
                      {transcription[item]?.marker ? (
                        <Tag>Start {transcription[item]?.marker}</Tag>
                      ) : null}
                    </Space>
                  }
                  description={formatFromChordDb(
                    `${transcription[item].note} ${transcription[item].tonality}`
                  )}
                />
              </List.Item>
            )}
          />
        </Collapse.Panel>
      </Collapse>
      {markerTime && (
        <Modal
          title={`Add a marker at ${convertToMinutes(markerTime)}`}
          open={markerModalVisible}
          onCancel={() => setMarkerModalVisible(false)}
          footer={null}
        >
          <Space direction="vertical">
            <p>
              This chord (
              {formatFromChordDb(
                `${transcription[markerTime].note} ${transcription[markerTime].tonality}`
              )}
              ) marks the begining of a:
            </p>
            <Select
              options={markerOptions}
              placeholder="Select marker"
              value={markerAction}
              onChange={(e) => setMarkerAction(e)}
              size="large"
            />
          </Space>
          <div style={{ textAlign: "end" }}>
            <Space>
              {transcription[markerTime]?.marker && (
                <Button onClick={() => handleRemoveMarker()}>
                  Remove Marker
                </Button>
              )}
              <Button type="primary" onClick={() => handleSaveMarker()}>
                Save Marker
              </Button>
            </Space>
          </div>
        </Modal>
      )}
    </>
  );
};

export default TranscriptionList;
