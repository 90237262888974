import React, { useContext, useState } from "react";
import { Col, Row, Typography, Card, Space, Avatar, notification } from "antd";
import {
  ToolFilled,
  ExportOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import AuthContext from "../../../contexts/auth-context";
import styles from "./tool-card.module.scss";
import { toggleToolFavorite } from "../../../services/tools";

const ToolCard = ({ tool }) => {
  const { isLoggedIn, token, toolbox, setToolbox } = useContext(AuthContext);
  const [isFavorite, setIsFavorite] = useState(tool.is_favorite);

  const onClickToolAction = async (t) => {
    try {
      await toggleToolFavorite(token, { ...t, is_favorite: isFavorite });
      setIsFavorite((prev) => !prev);

      const updatedToolbox = isFavorite
        ? toolbox.filter((t) => t.id !== tool.id)
        : [...toolbox, { ...tool, is_favorite: !isFavorite }];

      setToolbox(updatedToolbox);

      notification.open({
        message: isFavorite ? "Tool Removed" : "Tool Added",
        placement: "bottomRight",
      });
    } catch {
      notification.open({
        message: "Uh oh, something went wrong.",
        description:
          "We were unable to add this tool to your toolbox. Please try again later",
        placement: "bottomRight",
      });
    }
  };

  const getToolActionButton = (tool) => {
    if (!isLoggedIn) {
      return null;
    }

    if (isFavorite) {
      return [
        <div onClick={() => onClickToolAction(tool)}>
          <Space>
            <MinusOutlined />
            Remove from Toolbox
          </Space>
        </div>,
      ];
    }

    return [
      <div onClick={() => onClickToolAction(tool)}>
        <Space>
          <PlusOutlined />
          Add to Toolbox
        </Space>
      </div>,
    ];
  };
  return (
    <Card size="small" actions={getToolActionButton(tool)}>
      <Row justify="space-between">
        <Col xs={{ span: 24 }} sm={{ span: 18 }}>
          <a href={tool.key} target="_blank" rel="noreferrer">
            <Typography.Title level={5}>
              <Space className={styles["tool-title"]}>
                {tool.title} <ExportOutlined />
              </Space>
            </Typography.Title>
          </a>
          <Typography.Paragraph type="secondary">
            {tool.description}
          </Typography.Paragraph>
        </Col>
        <Col xs={{ span: 0 }} sm={{ span: 6 }} style={{ textAlign: "end" }}>
          <Avatar
            src={tool.image}
            shape="square"
            size={100}
            icon={<ToolFilled />}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default ToolCard;
