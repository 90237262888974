import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Result,
  Button,
  Spin,
  Divider,
  Card,
  Typography,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import SkeletonJamtrackCard from "../../components/cards/skeleton-jamtrack-card";
import AuthContext from "../../contexts/auth-context";
import { listChordsOfTheDay } from "../../services/chords";
import Chord from "@tombatossals/react-chords/lib/Chord";
import { guitar, guitarChords } from "../../chords";
import { getDateString } from "../../utils";

const loadingCards = [1, 2, 3].map((i) => {
  return (
    <Col key={i} span={24}>
      <SkeletonJamtrackCard />
    </Col>
  );
});

const ChordOfTheDayList = () => {
  const { token } = useContext(AuthContext);

  const [chords, setChords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [canPaginate, setCanPaginate] = useState(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const constructedChords = chords.map((c) => {
    const identifiedChord = c
      ? guitarChords.chords[c.key].find((ch) => ch.suffix === c.suffix)
        .positions[c.position]
      : false;

    return {
      chord: identifiedChord,
      key: c.key,
      tonality: c.suffix,
      date: c.created,
    };
  });

  const loadData = async () => {
    setCanPaginate(true);
    setIsLoading(true);
    setHasError(false);
    try {
      const { count, results } = await listChordsOfTheDay(token, {});
      if (count === results.length) setCanPaginate(false);
      console.log(results);
      setChords(results);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onPaginate = async () => {
    setIsPaginationLoading(true);
    setHasError(false);
    try {
      const { count, results } = await listChordsOfTheDay(token, {
        offset: chords.length,
      });
      const newChords = [...chords, ...results];
      if (count === newChords.length) setCanPaginate(false);
      setChords(newChords);
    } catch {
      setHasError(true);
    } finally {
      setIsPaginationLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <Row gutter={[0, 12]} justify="center">
        {isLoading && <>{loadingCards}</>}
        {hasError && !isLoading && (
          <Result
            title="Uh, something went wrong.."
            subTitle="Try refreshing the page, if that doesn't do it then something is broken."
            icon={<ExclamationCircleOutlined />}
          />
        )}
        {!isLoading && !hasError && chords.length > 0 && (
          <>
            <Col span={24} key={constructedChords[0].id}>
              <Card size="small" title="Chord of the Day">
                <Chord chord={constructedChords[0].chord} instrument={guitar} />
                <div style={{ textAlign: "center" }}>
                  <Typography.Text>
                    <b>
                      {constructedChords[0].key +
                        " " +
                        constructedChords[0].tonality}
                    </b>
                  </Typography.Text>
                </div>
              </Card>
            </Col>
            <Divider style={{ marginTop: 0, marginBottom: 0 }}>
              Previous Chords
            </Divider>
            {constructedChords.slice(1).map((chord) => {
              return (
                <Col span={24} key={chord.id}>
                  <Card size="small" title={getDateString(chord.date)}>
                    <Chord chord={chord.chord} instrument={guitar} />
                    <div style={{ textAlign: "center" }}>
                      <Typography.Text>
                        <b>{chord.key + " " + chord.tonality}</b>
                      </Typography.Text>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </>
        )}
        {!isLoading && !hasError && chords.length === 0 && <>Empty message</>}
        {!isLoading && !hasError && canPaginate && (
          <Col>
            {isPaginationLoading ? (
              <Spin />
            ) : (
              <Button onClick={() => onPaginate()}>Load More</Button>
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

export default ChordOfTheDayList;
