import React, { useContext, useEffect, useState } from "react";
import { Col, Row, Typography, Card, theme, Spin, Collapse } from "antd";
import Container from "../../components/layout/container/container";
import AuthContext from "../../contexts/auth-context";
import { listFaqs } from "../../services/faqs";
import MusicPlayerContext from "../../contexts/music-player-context";
import FooterLinks from "../../components/footer-links/footer-links";

const FaqPage = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { pause } = useContext(MusicPlayerContext);
  pause(); // stop playing music when page loads

  const { token } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [faqs, setFaqs] = useState([]);

  const loadData = async () => {
    setIsLoading(true);
    setHasError(true);
    try {
      const faqsData = await listFaqs(token);
      setFaqs(faqsData.results);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <div
        style={{
          padding: "12px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <div style={{ display: "block" }}>
          {isLoading ? (
            <Spin size="large" />
          ) : (
            <Row justify="center">
              <Col xs={24} md={18} lg={12}>
                <Card>
                  <div style={{ textAlign: "center" }}>
                    <Typography.Title
                      level={2}
                      style={{ color: colorPrimary }}
                      className="brand-title"
                    >
                      Jamtrackers
                    </Typography.Title>
                  </div>
                  <Typography.Title level={4}>Getting Started</Typography.Title>
                  <Typography.Paragraph>
                    Getting started on Jamtrackers is easy, you just have to
                    start jamming. Nonetheless, these are some of the most
                    common questions users have when they arrive.
                  </Typography.Paragraph>
                  <Collapse expandIconPosition="end" accordion>
                    {faqs.map((faq) => {
                      return (
                        <Collapse.Panel header={faq.question}>
                          <Typography.Text type="secondary">
                            {faq.answer}
                          </Typography.Text>
                        </Collapse.Panel>
                      );
                    })}
                  </Collapse>
                </Card>
                <br />
                <FooterLinks />
              </Col>
            </Row>
          )}
        </div>
      </div>
    </Container>
  );
};

export default FaqPage;
