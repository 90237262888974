import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Result,
  Button,
  Spin,
  Dropdown,
  Space,
  notification,
} from "antd";
import {
  ExclamationCircleOutlined,
  UsergroupAddOutlined,
  HeartOutlined,
  StarOutlined,
  DownOutlined,
  ReadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { listBlogs, deleteBlog } from "../../services/blogs";
import { listCourses } from "../../services/courses";
import AuthContext from "../../contexts/auth-context";
import SkeletonCourseCard from "../../components/cards/skeleton-course-card";
import CourseCard from "../../components/cards/course-card/course-card";

const loadingCards = [1, 2, 3].map((i) => {
  return (
    <Col key={i} md={12} xs={24}>
      <SkeletonCourseCard />
    </Col>
  );
});

const DefaultFeedSelector = () => {
  const { pathname } = useLocation();
  const { token, tokenClaim: isCreator } = useContext(AuthContext);
  const navigate = useNavigate();

  const dropdownItems = [
    {
      key: "/courses/recent",
      label: "Recently Added",
    },
  ];

  if (token) {
    dropdownItems.push(
      {
        key: "/courses/following",
        label: "Following",
      },
      {
        key: "/courses/favorites",
        label: "My Favorites",
      }
    );
  }
  if (token && isCreator) {
    dropdownItems.push({
      key: "/courses/drafts",
      label: "My Drafts",
    });
  }

  const handleDropdownClick = ({ item, key, keyPath, domEvent }) => {
    navigate(key);
  };

  const selectedDropdown = dropdownItems.find((i) => pathname.includes(i.key));

  return (
    <Dropdown
      menu={{
        onClick: handleDropdownClick,
        selectedKeys: [pathname],
        items: dropdownItems,
      }}
      trigger="click"
    >
      <Button type="text">
        <Space>
          <ReadOutlined />
          <b>Courses</b>
          {selectedDropdown.label}
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};

const CourseListPage = ({
  initialParams,
  feedTitle,
  titleComponent = <DefaultFeedSelector />,
}) => {
  const { token } = useContext(AuthContext);
  const [feed, setFeed] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [canPaginate, setCanPaginate] = useState(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const getEmptyMessage = () => {
    if (location.pathname === "/courses/following" && feed.length === 0) {
      return (
        <Result
          title="Follow musicians you like to generate a custom feed."
          subTitle="No results yet. Start following users to populate this feed."
          icon={<UsergroupAddOutlined />}
        />
      );
    }

    if (location.pathname === "/courses/favorites" && feed.length === 0) {
      return (
        <Result
          title="Save your favorite courses and find them here."
          subTitle="Nothing saved yet. Start adding favorites to populate this page."
          icon={<HeartOutlined />}
        />
      );
    }

    if (location.pathname === "/courses/featured" && feed.length === 0) {
      return (
        <Result
          title="There currently aren't any featured courses."
          subTitle="Check back later to find courses that have been recent favorites among the community."
          icon={<StarOutlined />}
        />
      );
    }

    if (location.pathname === "/courses/drafts" && feed.length === 0) {
      return (
        <Result
          title="You currently don't have any draft courses."
          icon={<EditOutlined />}
          extra={
            <Button onClick={() => navigate("/courses/new")}>
              Create Course
            </Button>
          }
        />
      );
    }
    return (
      <Result
        title="There currently aren't any published courses. Check back soon to find bite-sized courses that will help you become a better player!"
        icon={<StarOutlined />}
      />
    );
  };

  const loadData = async (params) => {
    setCanPaginate(true);
    setIsLoading(true);
    setHasError(false);
    try {
      const { count, results } = await listCourses(token, {
        ...initialParams,
        ...params,
      });
      if (count === results.length) setCanPaginate(false);
      setFeed(results);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onPaginate = async (params) => {
    setIsPaginationLoading(true);
    setHasError(false);
    try {
      const { count, results } = await listBlogs(token, {
        ...initialParams,
        ...params,
        offset: feed.length,
      });
      const newBlogs = [...feed, ...results];
      if (count === newBlogs.length) setCanPaginate(false);
      setFeed(newBlogs);
    } catch {
      setHasError(true);
    } finally {
      setIsPaginationLoading(false);
    }
  };

  const onDeleteBlog = async (blog) => {
    try {
      await deleteBlog(token, blog);

      const newFeed = feed.filter((b) => b.id !== blog.id);
      setFeed(newFeed);
      notification.open({
        message: "Lesson Deleted",
        description: `You have successfully deleted ${blog.title}.`,
      });
    } catch (error) {
      notification.open({
        message: "Uh oh...",
        description:
          "We are unable to delete the lesson at this time, please try again later.",
      });
    }
  };

  useEffect(() => {
    loadData({});
  }, [location.pathname]);

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: 10 }}>
        <Col>{titleComponent}</Col>
      </Row>

      <Row gutter={[12, 12]}>
        {isLoading && <>{loadingCards}</>}
        {hasError && !isLoading && (
          <Result
            title="Uh, something went wrong.."
            subTitle="Try refreshing the page, if that doesn't do it then something is broken."
            icon={<ExclamationCircleOutlined />}
          />
        )}
        {!isLoading && !hasError && feed.length > 0 && (
          <>
            {feed.map((course) => {
              return (
                <Col xs={24} sm={12} key={course.id}>
                  <CourseCard course={course} />
                </Col>
              );
            })}
          </>
        )}
        {!isLoading && !hasError && feed.length === 0 && (
          <Col span={24}>{getEmptyMessage()}</Col>
        )}
        {!isLoading && !hasError && canPaginate && (
          <Col>
            {isPaginationLoading ? (
              <Spin />
            ) : (
              <Button type="link" onClick={() => onPaginate(initialParams)}>
                Load More
              </Button>
            )}
          </Col>
        )}
      </Row >
    </>
  );
};

export default CourseListPage;
