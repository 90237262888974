import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Typography,
  Card,
  theme,
  Input,
  Button,
  Form,
  Alert,
} from "antd";
import Container from "../../components/layout/container/container";
import MusicPlayerContext from "../../contexts/music-player-context";
import FooterLinks from "../../components/footer-links/footer-links";
import {
  createCreatorApplication,
  listCreatorApplications,
} from "../../services/moderation";
import AuthContext from "../../contexts/auth-context";

const CreatorApplicationPage = () => {
  const {
    token,
    isLoggedIn,
    tokenClaim: { is_creator: isCreator },
  } = useContext(AuthContext);

  const [usersApplication, setUserApplication] = useState(null);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { pause } = useContext(MusicPlayerContext);
  pause(); // stop playing music when page loads

  const loadData = async () => {
    setHasError(false);
    setIsLoading(true);
    try {
      const { results } = await listCreatorApplications(token);
      if (results.length > 0) setUserApplication(results[0]);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const submitApplication = async () => {
    setHasError(false);
    setIsLoading(true);
    try {
      const body = {
        email: email,
      };
      const result = await createCreatorApplication(token, body);
      setUserApplication(result);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <div
        style={{
          padding: "12px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <div style={{ display: "block" }}>
          <Row justify="center">
            <Col xs={24} md={18} lg={12}>
              <Card>
                <div style={{ textAlign: "center" }}>
                  <Typography.Title
                    level={2}
                    style={{ color: colorPrimary }}
                    className="brand-title"
                  >
                    Jamtrackers
                  </Typography.Title>
                </div>
                <Typography.Title level={4}>Become a Creator</Typography.Title>
                <Typography.Paragraph>
                  Jamtrackers is a curated community focused on providing
                  quality practice material to fellow musicians. To ensure
                  quality, as well as to foster a safe and friendly environment
                  for our users, we moderate the content that is posted to our
                  site. Our primary way of doing this is to garauntee that the
                  musicians who can post content have passed an evaluation.
                </Typography.Paragraph>

                <Typography.Paragraph>
                  Users that have been given Creator Accounts are those who have
                  demonstrated an ability to create quality jam tracks and blogs.
                </Typography.Paragraph>

                <Typography.Paragraph>
                  As a Creator, you would gain access to the following features
                  and benefits:
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <b>Publish Jam Tracks and Blogs</b>
                  <div>
                    The primary benefit of having a Creator Account is that you
                    can publish your work. This means that you can publish your
                    music and writing, making it available to the community.
                  </div>
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <b>A Dedicated Musician Profile</b>
                  <div>
                    Your work will be compiled and displayed on a dedicated
                    webpage, meaning you can easily share it with others and
                    show off your musical portforlio.
                  </div>
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <b>Receive Donations</b>
                  <div>
                    Creators have the option of specifying a link where they accept donations. If a link is provided then other users will see the option to "Say Thanks" wherever your jam tracks and blogs ar published.
                  </div>
                </Typography.Paragraph>

                <Typography.Paragraph>
                  <b>Build a Following</b>
                  <div>
                    Users of Jamtrackers will be able to follow you, allowing
                    for your music to be shown in their personal feed.
                  </div>
                </Typography.Paragraph>
                {!isLoading && (
                  <>
                    {!isLoggedIn && (
                      <Alert
                        type="info"
                        message="To become a Creator you must be logged in."
                        description="The first step to becoming a Creator is to sign up for a regular account on Jamtrackers. Next, revisit this page and you will have the ability to apply. "
                      />
                    )}

                    {isCreator && (
                      <Alert
                        type="success"
                        message="Congratulations, you are a Creator!"
                        description="No need to apply, you are already a Creator. We look forward to listening to your jams."
                      />
                    )}

                    {!usersApplication && isLoggedIn && (
                      <Card size="small">
                        <Typography.Title level={5}>
                          Apply to Become a Creator
                        </Typography.Title>
                        <Typography.Paragraph>
                          <small>
                            To begin, please provide a valid email address where
                            we can contact you. This could be the same or a
                            different email address that was used to create your
                            account.
                          </small>
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                          <b>
                            <small>
                              The application process consists of verifying your
                              email, your location, and providing two content
                              items (jam tracks and/or blogs) that you have
                              already created.
                            </small>
                          </b>
                        </Typography.Paragraph>
                        <Form onFinish={submitApplication}>
                          <Input
                            placeholder="Email"
                            value={email}
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <Row justify="end" style={{ marginTop: 12 }}>
                            <Col>
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </Card>
                    )}

                    {usersApplication && isLoggedIn && !isCreator && (
                      <Alert
                        type="info"
                        message="Your Creator Application Has Been Received!"
                        description="We've received your application and will reach out to your shortly in order to get the ball rolling."
                      />
                    )}
                  </>
                )}
              </Card>
              <br />
              <FooterLinks />
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default CreatorApplicationPage;
