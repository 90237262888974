import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Typography,
  Row,
  Col,
  Form,
  Button,
  theme,
  Spin,
  Alert,
  Upload,
  message,
  Space,
  Collapse,
  Input,
  Switch,
} from "antd";
import { UserOutlined, PictureOutlined } from "@ant-design/icons";
import MusicPlayerContext from "../../contexts/music-player-context";
import AuthContext from "../../contexts/auth-context";
import FooterLinks from "../../components/footer-links/footer-links";
import { listUsers, updateUser } from "../../services/users";
import { convertToBase64 } from "../../utils";
import ProfileHeroCard from "../../components/cards/profile-hero-card";

const EditProfile = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { pause } = useContext(MusicPlayerContext);
  pause(); // stop playing music when page loads

  const { token, tokenClaim } = useContext(AuthContext);
  const navigate = useNavigate();

  const [userProfile, setUserProfile] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // const [email, setEmail] = useState("");

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profileImageData, setProfileImageData] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [coverImageData, setCoverImageData] = useState(null);

  const [about, setAbout] = useState(null);
  const [acceptsDonations, setAcceptsDonations] = useState(false)
  const [donationsLink, setDonationsLink] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const calculateShowSkipOption = () => {
    if (!userProfile) return false;
    const dateJoined = new Date(userProfile.date_joined);
    const today = new Date();

    const isToday =
      dateJoined.getFullYear() === today.getFullYear() &&
      dateJoined.getMonth() === today.getMonth() &&
      dateJoined.getDate() === today.getDate();

    return isToday;
  };

  const showSkipOption = calculateShowSkipOption();

  const formSumbitHandler = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    try {
      const body = {
        first_name: firstName,
        last_name: lastName,
        about: about,
        accepts_donations: acceptsDonations,
        donations_url: acceptsDonations ? donationsLink : null,
      };

      if (profileImageData) body["photo"] = profileImageData;
      if (coverImageData) body["cover_photo"] = coverImageData;
      const response = await updateUser(token, tokenClaim.user_id, body);
      navigate(`/musicians/${userProfile.id}`);
      setFirstName("");
    } catch (error) {
      const errorDict = JSON.parse(error.message)
      console.log(errorDict)
      setErrorMessage("An error occured while saving your profile. Please ensure all fields are completed correctly.");
    } finally {
      setIsLoading(false);
    }
  };

  const loadData = async () => {
    setIsLoading(true);
    setErrorMessage("");

    try {
      const { results: userResults } = await listUsers({
        username: tokenClaim.username,
      }); // TODO: change this to a retrieve call

      const {
        first_name: firstName,
        photo: savedProfilePhoto,
        cover_photo: savedCoverPhoto,
        // email: savedEmail,
        about,
        accepts_donations: wantsDonations,
        donations_url: donationsUrl,
      } = userResults[0];
      setUserProfile(userResults[0]);
      setFirstName(firstName);
      setProfilePhoto(savedProfilePhoto);
      setCoverPhoto(savedCoverPhoto);
      // setEmail(savedEmail);
      setAbout(about);
      setAcceptsDonations(wantsDonations)
      setDonationsLink(donationsUrl);
    } catch (error) {
      setErrorMessage("An error occured");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleProfilePhotoSelect = async (file) => {
    const acceptedMimeTypes = ["image/jpeg", "image/png"];
    const isJpgOrPng = acceptedMimeTypes.includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return;
    }

    const base64 = await convertToBase64(file);
    setProfileImageData(base64);
    return false; // prevent it from making upload call
  };

  const handleCoverPhotoSelect = async (file) => {
    const acceptedMimeTypes = ["image/jpeg", "image/png"];
    const isJpgOrPng = acceptedMimeTypes.includes(file.type);
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return;
    }

    const base64 = await convertToBase64(file);
    setCoverImageData(base64);
    return false; // prevent it from making upload call
  };

  useEffect(() => {
    loadData();
  }, []);

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Spin />
      </div>
    );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <div style={{ display: "block" }}>
        <Card style={{ maxWidth: 500, margin: 12 }}>
          <Row justify="center">
            <Col>
              <Typography.Title
                level={2}
                style={{ color: colorPrimary }}
                className="brand-title"
              >
                Jamtrackers
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Title level={4}>Edit Your Profile</Typography.Title>
              <Typography.Paragraph>
                Add or edit your profile picture, cover image, and details about
                yourself.
              </Typography.Paragraph>
              <ProfileHeroCard
                hideActionButtons={true}
                user={{
                  photo: profileImageData || profilePhoto,
                  cover_photo: coverImageData || coverPhoto,
                  username: userProfile.username,
                  date_joined: userProfile.date_joined,
                }}
              />
              <br />
              <Form
                name="normal_login"
                className="login-form"
                onFinish={formSumbitHandler}
                initialValues={{
                  remember: true,
                  firstName: firstName,
                }}
              >
                <Collapse accordion>
                  <Collapse.Panel header="Profile Picture & Cover Image">
                    <div>
                      <Typography.Title level={5}>
                        Profile Picture
                      </Typography.Title>
                      <Upload
                        name="avatar"
                        showUploadList={false}
                        beforeUpload={handleProfilePhotoSelect}
                        accept="image/png, image/jpeg"
                      >
                        <Button block>
                          <Space>
                            <UserOutlined />
                            <div>Change Profile Picture</div>
                          </Space>
                        </Button>
                      </Upload>
                    </div>
                    <br />
                    <div>
                      <Typography.Title level={5}>Cover Image</Typography.Title>
                      <Upload
                        name="avatar"
                        showUploadList={false}
                        beforeUpload={handleCoverPhotoSelect}
                        accept="image/png, image/jpeg"
                      >
                        <Button block>
                          <Space>
                            <PictureOutlined />
                            <div>Change Cover Image</div>
                          </Space>
                        </Button>
                      </Upload>
                    </div>
                  </Collapse.Panel>
                  <Collapse.Panel header="About">
                    <Typography.Paragraph>
                      Write a short paragraph to introduce yourself to other users.
                    </Typography.Paragraph>
                    <div style={{ marginBottom: 16 }}>
                      <Input.TextArea
                        autoFocus
                        maxLength={500}
                        showCount
                        value={about}
                        onChange={(c) => setAbout(c.target.value)}
                        placeholder="Let other users know something about you."
                      />
                    </div>
                  </Collapse.Panel>
                  <Collapse.Panel header="Donations">
                    <Row justify="space-between" align="middle" style={{ marginBottom: 10 }}>
                      <Col>
                        <Typography.Text>
                          <b>Accept Donations</b>
                        </Typography.Text>
                      </Col>
                      <Col>
                        <Switch checked={acceptsDonations} onChange={() => setAcceptsDonations((prev) => !prev)} />
                      </Col>
                    </Row>
                    <Typography.Paragraph>
                      If enabled, you can provide a link where users can provide donations to thank you for your contributions.
                    </Typography.Paragraph>
                    {acceptsDonations && (
                      <>
                        <Input
                          required={acceptsDonations}
                          type="url"
                          maxLength={200}
                          value={donationsLink}
                          onChange={(c) => setDonationsLink(c.target.value)}
                          placeholder="http://paypal.com/your-link"
                        />
                        <Typography.Text type="secondary">
                          <small>
                            Example of a valid URL; http://paypal.com/your-profile
                          </small>
                        </Typography.Text>

                      </>
                    )}

                  </Collapse.Panel>

                  {/* <Collapse.Panel header="Email">
                    <Input
                      defaultValue={email}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Collapse.Panel> */}

                  {/* <Collapse.Panel header="Location">foo</Collapse.Panel> */}

                  {/* <Collapse.Panel header="Personal Details">
                    <Form.Item name="firstName" label="First Name">
                      <Input
                        defaultValue={firstName}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Form.Item>
                    <Form.Item name="lastName" label="Last Name">
                      <Input
                        defaultValue={lastName}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Form.Item>
                  </Collapse.Panel> */}
                </Collapse>
                <br />

                {errorMessage && (
                  <>
                    <Alert message={errorMessage} type="warning" closable />
                    <br />
                  </>
                )}
                <Row justify="center">
                  <Col>
                    <div style={{ textAlign: "center" }}>
                      <Space direction="vertical">
                        <Space>
                          <Button onClick={() => navigate("/")}>Cancel</Button>
                          <Button type="primary" htmlType="submit">
                            Save
                          </Button>
                        </Space>
                        {showSkipOption && (
                          <div>
                            <Typography.Link onClick={() => navigate("/")}>
                              Skip for now
                            </Typography.Link>
                          </div>
                        )}
                      </Space>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
        <br />
        <FooterLinks />
      </div>
    </div>
  );
};

export default EditProfile;
