const baseUrl = process.env.REACT_APP_BASE_URL;

export const listJamtracks = async (token, params = {}) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };

  const queryArray = [];
  for (const [key, value] of Object.entries(params)) {
    queryArray.push(`${key}=${value}`);
  }
  const queryString = queryArray.length ? "?" + queryArray.join("&") : "";

  const url = `${baseUrl}/api/jamtracks/${queryString}`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const getJamtrack = async (token, id) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };

  const url = `${baseUrl}/api/jamtracks/${id}/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const toggleJamtrackFavorite = async (token, jamtrack) => {
  const data = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
    body: JSON.stringify({
      is_favorite: !jamtrack.is_favorite,
    }),
  };

  const url = `${baseUrl}/api/jamtracks/${jamtrack.id}/favorite/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const updateJamtrack = async (token, jamtrack, updatedFields) => {
  const data = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
    body: JSON.stringify(updatedFields),
  };

  const url = `${baseUrl}/api/jamtracks/${jamtrack.id}/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const createJamtrack = async (token, jamtrack) => {
  const data = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(jamtrack),
  };

  const url = `${baseUrl}/api/jamtracks/`;
  const response = await fetch(url, data);
  if (response.status !== 201) {
    const json = await response.json();
    throw Error(JSON.stringify(json));
  }
  return response.json();
};

export const deleteJamtrack = async (token, jamtrack) => {
  const data = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const url = `${baseUrl}/api/jamtracks/${jamtrack.id}/`;
  const response = await fetch(url, data);
  if (response.status !== 204) {
    throw Error;
  }
  return response;
};
