import React, { useContext, useState } from "react";
import {
  Card,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  theme,
  Spin,
  Alert,
} from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import MusicPlayerContext from "../../contexts/music-player-context";
import { getToken } from "../../services/authentication";
import AuthContext from "../../contexts/auth-context";
import { useNavigate } from "react-router-dom";
import FooterLinks from "../../components/footer-links/footer-links";

const LoginPage = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { pause } = useContext(MusicPlayerContext);
  pause(); // stop playing music when page loads

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value.toLowerCase());
  };

  const formSumbitHandler = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    try {
      const response = await getToken({
        username: username,
        password: password,
      });

      authCtx.login(response.access);
      navigate("/");

      setUsername("");
      setPassword("");
    } catch (error) {
      setErrorMessage("The provided credentials were not correct.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <div style={{ display: "block" }}>
        <Card>
          <Row justify="center">
            <Col>
              <Typography.Title
                level={2}
                style={{ color: colorPrimary }}
                className="brand-title"
              >
                Jamtrackers
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Title level={4}>Sign In</Typography.Title>
              <Typography.Paragraph>
                Access and manage your music in Jamtrackers
              </Typography.Paragraph>
              <Form
                size="large"
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={formSumbitHandler}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Username!",
                    },
                  ]}
                >
                  <Input
                    value={username}
                    onChange={(e) => handleUsernameChange(e)}
                    prefix={<UserOutlined />}
                    placeholder="Username"
                  />
                </Form.Item>
                <Form.Item
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                {errorMessage && (
                  <>
                    <Alert message={errorMessage} type="warning" closable />
                    <br />
                  </>
                )}
                <Row justify="center">
                  <Col>
                    {isLoading ? (
                      <Spin />
                    ) : (
                      <Button type="primary" htmlType="submit">
                        Sign In
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <br />
          <Row justify="center">
            <Col>
              <b>
                <Typography.Link onClick={() => navigate("/forgot-password")}>
                  Forgot username or password?
                </Typography.Link>
              </b>
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <Typography.Text>Don't have an account?</Typography.Text>
              <Typography.Link onClick={() => navigate("/register")}>
                {" "}
                Register{" "}
              </Typography.Link>
              <Typography.Text>for free.</Typography.Text>
            </Col>
          </Row>
        </Card>
        <br />
        <FooterLinks />
      </div>
    </div>
  );
};

export default LoginPage;
