import React from "react";
import { Card, Col, Row, Typography } from "antd";
import classes from "./other-card.module.scss"
import drumImage from "./drum-icon.svg"
import bassImage from "./bass-icon.svg"
import { useNavigate } from "react-router-dom";


const DrumBassCard = ({ image, smallText, bigText, navigateTo }) => {
    const navigate = useNavigate()
    return (
        <Card size="small"
            style={{
                overflow: "hidden",
                backgroundImage: `url(${image})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
            }}
            onClick={() => navigate(navigateTo)}
            hoverable
        >
            <Row style={{ height: 150 }} align="bottom">
                <Col xs={{ span: 24 }} sm={{ span: 20 }}>
                    <Card
                        size="small"
                        bordered={false}
                        style={{ backgroundColor: "rgb(255, 255, 255, 92%)", boxShadow: "0px 2px 3px lightgray" }}
                    >
                        <Typography.Text type="secondary">
                            <small>{smallText}</small>
                        </Typography.Text>
                        <Typography.Title level={5} style={{ marginBottom: 0, marginTop: 0 }}>{bigText}</Typography.Title>
                    </Card>
                </Col>
            </Row>
        </Card >
    )

};

export default DrumBassCard;