import { useState, useEffect } from "react";
import { Alert, Button } from "antd";
import { Link } from "react-router-dom";

const CookieAlert = () => {
  // Assume user has accepted to prevent rendering flash
  // Only render notice if no acceptance in local storage
  const [accepted, setAccepted] = useState(true);

  useEffect(() => {
    const isAccepted = localStorage.getItem("cookieAccepted");
    if (!isAccepted) {
      setAccepted(false);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieAccepted", true);
    setAccepted(true);
  };

  if (accepted) {
    return null;
  }

  const description = (
    <>
      We use cookies for functionality purposes. By using our website, you
      consent to the use of cookies in accordance with our{" "}
      <Link to="/cookie-policy">Cookie Policy</Link>.
    </>
  );

  if (!accepted) {
    return (
      <Alert
        banner
        style={{
          position: "fixed",
          bottom: 0,
          width: "100vw",
          zIndex: 20000,
        }}
        message="Cookie Notice"
        description={description}
        type="info"
        showIcon
        action={
          <Button type="primary" onClick={handleAccept}>
            Got it!
          </Button>
        }
      />
    );
  }

  return null;
};

export default CookieAlert;
