import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel, Tag } from "antd";
import {
  OrderedListOutlined,
  TableOutlined,
  CaretRightFilled,
  BulbOutlined,
} from "@ant-design/icons";
import HeroHighlightCard from "../../../components/cards/hero-highlight-card";
import welcome from "./media/welcome.svg"
import play from "./media/play.svg"
import queue from "./media/queue.svg"
// import tag from "./media/tag.svg"
import chords from "./media/chords.svg"
import learn from "./media/learn.svg"
import finish from "./media/finish.svg"
import discover from "./media/discover.svg"

const slides = [
  {
    id: "welcome",
    title: "Welcome to Jamtrackers",
    desc: "We're here to help you become a better musician. These quick steps will help you learn how to use this site.",
    buttonText: "Start",
    image: welcome,
  },
  {
    id: "play",
    title: "Play a Jam Track",
    desc: (
      <>
        Jam tracks help you build improvisational skills. Find a track from the list below and press{" "}
        <Tag>
          <CaretRightFilled />
        </Tag>
        to play it.
      </>
    ),
    buttonText: "Next Step",
    image: play,
  },
  {
    id: "discover",
    title: "Discover What to Play",
    desc: (
      <>
        Click on the{" "}
        <Tag icon={<BulbOutlined />}>
          Show Scales for the Key of ...
        </Tag>
        panel that appears to find infortion about the key and view scales on an interactive fretboard.
      </>
    ),
    buttonText: "Next Step",
    image: discover,
  },
  // {
  //   title: "Jamtrack Tags",
  //   desc: "Jamtracks have a number of tags to indicate its genre, key, tempo, time signature, and more.",
  //   buttonText: "Next Step",
  //   image: tag,
  // },
  {
    id: "chords",
    title: "Follow Chord Changes in Real Time",
    desc: (
      <>
        Find another track from the list below with the{" "}
        <Tag icon={<TableOutlined />}>
          Live Chords
        </Tag>tag and press play. Then click on the{" "}
        <Tag icon={<TableOutlined />}>
          Live Chords
        </Tag>button at the top right of the page to follow chord changes.
      </>
    ),
    buttonText: "Next Step",
    image: chords,
  },
  {
    id: "queue",
    title: "Add to the Queue",
    desc: (
      <>
        Queue another track to play next by pressing the <Tag><OrderedListOutlined /></Tag>button. View your queue by clicking the same button in the top right corner.
      </>
    ),
    buttonText: "Next Step",
    image: queue,
  },
  {
    id: "learn",
    title: "Practice and Learn",
    desc: "In addition to tracks, Jamtrackers also offers a selection of short blogs and tools to assist your learning.",
    buttonText: "Next Step",
    image: learn,
  },
  {
    id: "finish",
    title: "Let's Start Jamming!",
    desc: "Join Jamtackers to access all features, including the ability to follow musicians and favorite tracks.",
    buttonText: "Create Free Account",
    image: finish,
  },
]

const TutorialCarousel = () => {
  const carouselRef = useRef(null);
  const navigate = useNavigate()
  const getClickAction = (id) => {
    if (id === "finish") return () => navigate("/register")
    return () => carouselRef.current.next()
  }
  return (
    <Carousel
      ref={carouselRef}
      infinite={false}
      effect="fade"
      dotPosition="top"
      style={{ maxWidth: "calc(100vw - 30px)" }} // because the Carousel component seems broken?
    >
      {slides
        .map((c) => (
          <HeroHighlightCard
            key={c.id}
            image={c?.image}
            title={c.title}
            description={c.desc}
            onClickButton={getClickAction(c.id)}
            buttonText={c.buttonText}
          />
        ))}
    </Carousel>
  );
};

export default TutorialCarousel;