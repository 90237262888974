const baseUrl = process.env.REACT_APP_BASE_URL;

export const listUsers = async (params = {}) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const queryArray = [];
  for (const [key, value] of Object.entries(params)) {
    queryArray.push(`${key}=${value}`);
  }
  const queryString = queryArray.length ? "?" + queryArray.join("&") : "";

  const url = `${baseUrl}/api/users/${queryString}`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const retrieveUser = async (token = null, userId, params = {}) => {
  const data = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
  };

  const queryArray = [];
  for (const [key, value] of Object.entries(params)) {
    queryArray.push(`${key}=${value}`);
  }
  const queryString = queryArray.length ? "?" + queryArray.join("&") : "";

  const url = `${baseUrl}/api/users/${userId}${queryString}`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};

export const createUser = async (user) => {
  const data = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  };

  const url = `${baseUrl}/api/users/`;
  const response = await fetch(url, data);
  if (response.status !== 201) {
    const errors = await response.json();
    throw Error(JSON.stringify(errors));
  }
  return response.json();
};

export const updateUser = async (token, userId, body) => {
  const data = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
    body: JSON.stringify(body),
  };

  const url = `${baseUrl}/api/users/${userId}/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    const errors = await response.json();
    throw Error(JSON.stringify(errors));
  }
  return response.json();
};

export const toggleFollowUser = async (token, user, isFollowing) => {
  const data = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
    body: JSON.stringify({
      is_following: !isFollowing,
    }),
  };

  const url = `${baseUrl}/api/users/${user.id}/follow/`;
  const response = await fetch(url, data);
  if (response.status !== 200) {
    throw Error;
  }
  return response.json();
};


export const deleteUser = async (token, user) => {
  const data = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const url = `${baseUrl}/api/users/${user.id}/`;
  const response = await fetch(url, data);
  if (response.status !== 204) {
    throw Error;
  }
  return response;
};

