import { Card, Skeleton } from "antd";
import { useContext } from "react";
import AuthContext from "../../contexts/auth-context";

const SkeletonBlogCard = () => {
  const { isLoggedIn } = useContext(AuthContext);

  const actions = [];

  if (isLoggedIn) actions.push(<Skeleton.Button key={3} size="small" active />);
  return (
    <Card actions={actions} size="small">
      <Skeleton avatar={{ shape: "square" }} active paragraph={{ rows: 0 }} />
      <Skeleton active paragraph={{ rows: 2 }} />
    </Card>
  );
};

export default SkeletonBlogCard;
