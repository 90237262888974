import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Segmented,
  Skeleton,
  Card,
  Result,
  notification,
  Spin,
  Button,
  Space,
} from "antd";
import {
  ExclamationCircleOutlined,
  NotificationOutlined,
} from "@ant-design/icons";
import {
  listNotifications,
  updateNotification,
} from "../../services/notifications";
import AuthContext from "../../contexts/auth-context";
import NotificationCard from "../../components/cards/notification-card";

const notificationTabOptions = {
  NEW: "New",
  SEEN: "Seen",
  ALL: "All",
};

const NotificationListPage = () => {
  const { token, getUnreadNotifCount } = useContext(AuthContext);

  const [currentTab, setCurrentTab] = useState(notificationTabOptions.NEW);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [canPaginate, setCanPaginate] = useState(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const loadData = async () => {
    setIsLoading(true);
    setHasError(false);

    try {
      const params =
        currentTab === notificationTabOptions.ALL
          ? {}
          : { is_seen: currentTab === notificationTabOptions.SEEN };
      const notificationData = await listNotifications(token, params);

      setCanPaginate(!!notificationData.next);
      setNotifications(notificationData.results);
    } catch {
      console.log("Error loading notifications");
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleIsSeen = async (notif) => {
    const newIsSeen = !notif.is_seen;
    try {
      const updatedNotification = await updateNotification(token, notif, {
        is_seen: newIsSeen,
      });

      if (currentTab === notificationTabOptions.ALL) {
        const index = notifications.indexOf(notif);
        const updatedNotifs = notifications.toSpliced(
          index,
          1,
          updatedNotification
        );
        setNotifications(updatedNotifs);
      } else {
        const updatedNotifs = notifications.filter((n) => n.id !== notif.id);
        setNotifications(updatedNotifs);
      }
      getUnreadNotifCount();
    } catch {
      notification.open({
        message: "Uh oh, something happened...",
        description:
          "We were unable to save the changes to this notification. Please try again later.",
        placement: "bottomRight",
      });
    }
  };

  const onPaginate = async () => {
    setIsPaginationLoading(true);
    setHasError(false);
    try {
      const params =
        currentTab === notificationTabOptions.ALL
          ? {}
          : { is_seen: currentTab === notificationTabOptions.SEEN };
      const { count, results } = await listNotifications(token, {
        ...params,
        offset: notifications.length,
      });
      const newNotifications = [...notifications, ...results];
      if (count === newNotifications.length) setCanPaginate(false);
      setNotifications(newNotifications);
    } catch {
      setHasError(true);
    } finally {
      setIsPaginationLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [currentTab]);

  const sortedNotifications =
    notifications.sort((a, b) => new Date(b.created) - new Date(a.created)) ||
    [];

  return (
    <>
      <Row
        gutter={[0, 12]}
        justify="space-between"
        style={{ marginBottom: 10 }}
      >
        <Col>
          <Button type="text">
            <Space>
              <NotificationOutlined />
              <b>Notifications</b>
            </Space>
          </Button>
        </Col>
        <Col>
          <Segmented
            value={currentTab}
            onChange={(newTab) => setCurrentTab(newTab)}
            options={Object.values(notificationTabOptions)}
          />
        </Col>
      </Row>
      <Row gutter={[0, 12]} justify="center">
        {isLoading && (
          <>
            {[1, 2, 3].map((x) => {
              return (
                <Col span={24} key={x}>
                  <Card>
                    <Skeleton active paragraph={{ rows: 1 }} />
                  </Card>
                </Col>
              );
            })}
          </>
        )}
        {!isLoading && !hasError && (
          <>
            {sortedNotifications.map((n) => {
              return (
                <Col span={24} key={n.id}>
                  <NotificationCard
                    notification={n}
                    onClickSeenButton={toggleIsSeen}
                  />
                </Col>
              );
            })}
            {canPaginate && (
              <Col>
                {isPaginationLoading ? (
                  <Spin />
                ) : (
                  <Button onClick={() => onPaginate()}>Load More</Button>
                )}
              </Col>
            )}
          </>
        )}
        {!isLoading && !hasError && notifications.length === 0 && (
          <Result
            title="You're all caught up"
            subTitle="You don't have any notifications to view."
            icon={<NotificationOutlined />}
          />
        )}
        {!isLoading && hasError && (
          <Result
            title="Uh, something went wrong.."
            subTitle="Try refreshing the page, if that doesn't do it then something is broken."
            icon={<ExclamationCircleOutlined />}
          />
        )}
      </Row>
    </>
  );
};

export default NotificationListPage;
