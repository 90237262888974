import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Skeleton,
  Button,
  Result,
  Typography,
  Space,
  Statistic,
  theme,
} from "antd";
import {
  ExclamationCircleOutlined,
  CustomerServiceOutlined,
  CommentOutlined,
  ClockCircleOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import JamtrackListPage from "../jamtrack-list";
import BlogListPage from "../blog-list";
import { retrieveUser } from "../../services/users";
import AuthContext from "../../contexts/auth-context";
import ProfileHeroCard from "../../components/cards/profile-hero-card";
import FeedHeader from "../../components/typography/feed-header";

const QuickStatsCard = ({ user }) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const joinedDate = new Date(user.date_joined).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
  });

  return (
    <Card size="small">
      <Typography.Title level={5}>Quick Stats</Typography.Title>
      <Row justify="space-between">
        <Col>
          <Statistic
            title="Member Since"
            value={joinedDate}
            valueStyle={{ color: colorPrimary }}
          />
        </Col>
        <Col>
          <Row>
            <Col>
              <Space size="large">
                <Statistic
                  title="Followers"
                  value={user.follower_count}
                  valueStyle={{ color: colorPrimary }}
                />
                <Statistic
                  title="Jam Tracks"
                  value={user.jamtrack_count}
                  valueStyle={{ color: colorPrimary }}
                />
                <Statistic
                  title="Blogs"
                  value={user.blog_count}
                  valueStyle={{ color: colorPrimary }}
                />
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

const AboutCard = ({ about }) => {
  return (
    <Card size="small">
      <Typography.Title level={5}>About</Typography.Title>
      <Typography.Paragraph>{about}</Typography.Paragraph>
    </Card>
  );
};

const DonationsCard = ({ donationsUrl }) => {
  return (
    <Card size="small">
      <Typography.Title level={5}>Donations</Typography.Title>
      <Typography.Paragraph>Share a small donation to say thanks, and to encourage them to keep up the great work!</Typography.Paragraph>
      <Row justify="end">
        <Col>
          <a href={donationsUrl} target="_blank" rel="noreferrer">
            <Button type="primary" block >
              <Space>
                <DollarOutlined />
                Say Thanks
              </Space>
            </Button>
          </a>
        </Col>
      </Row>
    </Card>
  );
};

const UserProfile = () => {
  const { userId } = useParams();
  const { token } = useContext(AuthContext);

  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const loadData = async () => {
    setHasError(false);
    setIsLoading(true);

    try {
      const user = await retrieveUser(token, userId);
      setUser(user);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [userId]);

  if (hasError) {
    return (
      <Card size="small">
        <Result
          title="User not found."
          subTitle="Oops, we couldn't find this user. Make sure the link to their profile is correct."
          icon={<ExclamationCircleOutlined />}
        />
      </Card>
    );
  }

  if (isLoading) {
    return (
      <Row>
        <Col span={24}>
          <Card size="small">
            <Skeleton active />
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Row style={{ marginBottom: 10 }} gutter={[12, 12]}>
        <Col span={24}>
          <ProfileHeroCard user={user} />
        </Col>
        <Col span={24}>
          <QuickStatsCard user={user} />
        </Col>
        {user.about && (
          <Col span={24}>
            <AboutCard about={user.about} />
          </Col>
        )}

        {user.accepts_donations && user.donations_url && (
          <Col span={24}>
            <DonationsCard donationsUrl={user.donations_url} />
          </Col>
        )}
      </Row>

      {!user.jamtrack_count && !user.blog_count && (
        <Result
          title={`${user.username} hasn't posted anything yet.`}
          subTitle="Check back later to find jam tracks and blogs posted by this user."
          icon={<ClockCircleOutlined />}
        />
      )}

      {user.jamtrack_count > 0 && (
        <JamtrackListPage
          initialParams={{ user: user.id, limit: 5 }}
          feedTitle="User Profile"
          titleComponent={
            <FeedHeader icon={<CustomerServiceOutlined />} title={`Jam tracks by ${user.username}`} />
          }
        />
      )}

      {user.blog_count > 0 && (
        <BlogListPage
          initialParams={{ user: user.id, limit: 5, is_published: true }}
          feedTitle="User Profile"
          titleComponent={
            <FeedHeader icon={<CommentOutlined />} title={`Blogs by ${user.username}`} style={{ marginTop: 12, marginBottom: 10 }} />
          }
        />
      )}
    </>
  );
};

export default UserProfile;
