import { Col, Row, Skeleton } from "antd";
import React from "react";
import SkeletonJamtrackCard from "../../components/cards/skeleton-jamtrack-card";

const HomeSkeleton = () => {
  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Skeleton.Button active />
      </Col>
      <Col span={24}>
        <Skeleton.Button active block style={{ height: 250 }}>
          {" "}
        </Skeleton.Button>
      </Col>
      <Col span={24}>
        <Skeleton.Button active />
      </Col>
      {[1, 2, 3].map((i) => {
        return (
          <Col key={i} span={24}>
            <SkeletonJamtrackCard />
          </Col>
        );
      })}
    </Row>
  );
};

export default HomeSkeleton;
