import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Space,
  Slider,
  Input,
  Card,
  Row,
  Col,
  Select,
  Collapse,
  theme,
} from "antd";
import {
  CaretRightFilled,
  PauseOutlined,
  ForwardOutlined,
  BackwardOutlined,
} from "@ant-design/icons";
import { convertToMinutes } from "../../utils";
import { guitarChords, guitar } from "../../chords";
import { formatFromChordDb } from "../../utils";
import Chord from "@tombatossals/react-chords/lib/Chord";
import { roundToNearestQuarter } from "../../utils";

const PlacementChordCard = ({
  chord,
  isSelected,
  placementIndex,
  onSetPlacement,
}) => {
  const {
    token: { colorPrimaryBorder },
  } = theme.useToken();

  const handleClick = () => {
    onSetPlacement(placementIndex);
  };
  return (
    <Card
      size="small"
      onClick={handleClick}
      style={
        isSelected ? { borderColor: colorPrimaryBorder, borderWidth: 3 } : {}
      }
    >
      <Chord chord={chord} instrument={guitar} />
    </Card>
  );
};

const TranscribeControls = ({
  jamtrack,
  transcription,
  onChangeTranscription,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  // const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [trackProgress, setTrackProgress] = useState(0);
  const [note, setNote] = useState(null);
  const [tonality, setTonality] = useState(null);
  const [placement, setPlacement] = useState(0);

  const audioRef = useRef(new Audio(jamtrack.track));
  const intervalRef = useRef();

  const scrubSeconds = 0.25;

  const chordPlacements =
    note && tonality
      ? guitarChords.chords[note].find((n) => n.suffix === tonality).positions
      : [];

  const keyOptions = [
    ...Object.keys(guitarChords.chords).map((key) => {
      return {
        value: key,
        label: formatFromChordDb(key),
      };
    }),
  ];

  const tonalityOptions = note
    ? guitarChords.chords[note].map((suf) => {
        return {
          value: suf.suffix,
          label: formatFromChordDb(suf.suffix),
        };
      })
    : [];

  const startTimer = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setTrackProgress(roundToNearestQuarter(audioRef.current.currentTime));
    }, [250]);
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const handleSliderChange = (seconds) => {
    setTrackProgress(seconds);
    audioRef.current.currentTime = seconds;
  };

  const handleToggleForward = () => {
    setTrackProgress(trackProgress + scrubSeconds);
    audioRef.current.currentTime = audioRef.current.currentTime + scrubSeconds;
  };

  const handleToggleBackward = () => {
    setTrackProgress(trackProgress - scrubSeconds);
    audioRef.current.currentTime = audioRef.current.currentTime - scrubSeconds;
  };

  const handleAddChord = (newChord) => {
    const newTranscription = {
      ...transcription,
      [trackProgress]: {
        note,
        tonality,
        placement,
      },
    };

    onChangeTranscription(newTranscription);
    setNote(null);
    setTonality(null);
    setPlacement(0);
  };

  useEffect(() => {
    startTimer();
  }, []);

  return (
    <>
      <Slider
        value={trackProgress}
        max={audioRef.current.duration}
        onChange={handleSliderChange}
        tooltip={{ formatter: (value) => convertToMinutes(value) }}
      />
      <div style={{ textAlign: "center" }}>
        <Space>
          <Button
            type="text"
            size="large"
            onClick={handleToggleBackward}
            disabled={audioRef.current.currentTime === 0}
          >
            <BackwardOutlined />
            {scrubSeconds}s
          </Button>
          <Button type="text" size="large" onClick={togglePlayPause}>
            {isPlaying ? <PauseOutlined /> : <CaretRightFilled />}
          </Button>
          <Button
            type="text"
            size="large"
            onClick={handleToggleForward}
            disabled={
              audioRef.current.currentTime === audioRef.current.duration
            }
          >
            {scrubSeconds}s
            <ForwardOutlined />
          </Button>
        </Space>
      </div>
      <br />
      <Card>
        <Row gutter={[8, 8]}>
          <Col xs={24} lg={4}>
            <Input value={convertToMinutes(trackProgress)} disabled />
          </Col>
          <Col xs={24} lg={7}>
            <Select
              style={{
                width: "100%",
              }}
              placeholder="Note"
              defaultValue={note}
              value={note}
              onChange={(value, option) => setNote(value)}
              allowClear
              options={keyOptions}
            />
          </Col>
          <Col xs={24} lg={7}>
            <Select
              style={{
                width: "100%",
              }}
              placeholder="Tonality"
              defaultValue={tonality}
              value={tonality}
              onChange={(value, option) => setTonality(value)}
              allowClear
              options={tonalityOptions}
              disabled={!note}
            />
          </Col>
          <Col xs={24} lg={6}>
            <Button
              block
              type="primary"
              onClick={handleAddChord}
              disabled={!note || !tonality}
            >
              Add Chord
            </Button>
          </Col>
        </Row>
        {/* Leave the below part in until Im sure I dont want chord placements */}
        {/* {note && tonality && (
          <>
            <br />
            <Collapse size="small" defaultActiveKey={"1"}>
              <Collapse.Panel header="Select Chord Placement" key={"1"}>
                <Row gutter={[8, 8]}>
                  {chordPlacements.map((c, index) => (
                    <Col xs={12} md={8}>
                      <PlacementChordCard
                        chord={c}
                        placementIndex={index}
                        onSetPlacement={setPlacement}
                        isSelected={placement === index}
                      />
                    </Col>
                  ))}
                </Row>
              </Collapse.Panel>
            </Collapse>
          </>
        )} */}
      </Card>
    </>
  );
};

export default TranscribeControls;
