import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Dropdown, notification } from "antd";
import {
  MoreOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  ShareAltOutlined,
  EditOutlined,
} from "@ant-design/icons";
import AuthContext from "../../../contexts/auth-context";
import { toggleFollowUser } from "../../../services/users";

const CourseActionButtons = ({ course, size = "medium" }) => {
  const { token, tokenClaim, isLoggedIn } = useContext(AuthContext);
  const [isFollowing, setIsFollowing] = useState(course.in_following_feed)

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleFollowClick = async () => {
    try {
      await toggleFollowUser(token, course.user, course.in_following_feed);
      setIsFollowing((current) => !current);
      openNotification(
        isFollowing ? "User Unfollowed" : "User Followed",
        `You ${isFollowing ? "have unfollowed" : "are now following"
        } ${course.user.username}.`
      );
    } catch {
      console.log("Follow not saved");
      // TODO: provide feedback to user
    }
  };

  const handleClickShare = () => {
    let hostname = window.location.hostname;
    if (hostname === "localhost") hostname = "localhost:3000";
    navigator.clipboard.writeText(hostname + `/courses/${course.id}`);

    openNotification(
      "Copied to Clipboard",
      "A direct link to this jamtrack has been copied to your clipboard."
    );
  };

  const openNotification = (message, description) => {
    notification.open({
      message,
      description,
      placement: "bottomRight",
    });
  };

  const items = [
    {
      key: "Share",
      label: "Share",
      icon: <ShareAltOutlined />,
      onClick: handleClickShare,
    },
  ];

  if (isLoggedIn && course.user.username !== tokenClaim.username) {
    items.push({
      key: "follow",
      label:
        (isFollowing ? "Unfollow" : "Follow") +
        " " +
        course.user.username,
      icon: isFollowing ? (
        <UserDeleteOutlined />
      ) : (
        <UserAddOutlined />
      ),
      onClick: handleFollowClick,
    });
  } else if (isLoggedIn) {
    items.push({
      key: "edit",
      label: "Edit Course",
      icon: <EditOutlined />,
      onClick: () => navigate(`/courses/${course.id}/edit`),
    });
  }

  return (
    <>
      <Dropdown
        trigger="click"
        menu={{
          items,
        }}
      >
        <Button icon={<MoreOutlined />} size={size} />
      </Dropdown>
    </>
  );
};

export default CourseActionButtons;
