import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Card,
  Typography,
  Row,
  Col,
  Form,
  Input,
  Button,
  theme,
  Spin,
  Alert,
  List,
} from "antd";
import {
  LockOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import MusicPlayerContext from "../../contexts/music-player-context";
import FooterLinks from "../../components/footer-links/footer-links";
import { capitalizeWord } from "../../utils";
import {
  confirmPasswordResetRequest,
  getPasswordResetRequest,
} from "../../services/authentication";

const PasswordReset = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { pause } = useContext(MusicPlayerContext);
  pause(); // stop playing music when page loads

  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const { passwordResetId } = useParams();

  const loadData = async () => {
    try {
      await getPasswordResetRequest(passwordResetId);
    } catch {
      navigate("/");
    }
  };

  const formSumbitHandler = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    try {
      if (password !== passwordConfirm) {
        setErrorMessage("The passwords do not match.");
      } else {
        await confirmPasswordResetRequest(passwordResetId, {
          password: password,
        });

        navigate("/login");
        setPassword("");
        setPasswordConfirm("");
      }
    } catch (error) {
      const errors = JSON.parse(error.message);
      const errorJsx = (
        <>
          {Object.keys(errors).map((k) => {
            return (
              <div>
                <b>{capitalizeWord(k)}</b>
                <List
                  size="small"
                  dataSource={errors[k]}
                  renderItem={(item) => <List.Item>{item}</List.Item>}
                />
              </div>
            );
          })}
        </>
      );
      setErrorMessage(errorJsx);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <div style={{ display: "block" }}>
        <Card>
          <Row justify="center">
            <Col>
              <Typography.Title
                level={2}
                style={{ color: colorPrimary }}
                className="brand-title"
              >
                Jamtrackers
              </Typography.Title>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography.Title level={4}>Set New Password</Typography.Title>
              <Typography.Paragraph>
                Insert your new password below
              </Typography.Paragraph>
              <Form
                size="large"
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={formSumbitHandler}
              >
                <Form.Item
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Password"
                    iconRender={(visible) =>
                      visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>
                <Form.Item
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  name="password-confirm"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your Password!",
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined />}
                    placeholder="Confirm Password"
                    iconRender={(visible) =>
                      visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                    }
                  />
                </Form.Item>

                {errorMessage && (
                  <>
                    <Alert message={errorMessage} type="warning" />
                    <br />
                  </>
                )}
                <Row justify="center">
                  <Col>
                    {isLoading ? (
                      <Spin />
                    ) : (
                      <Button type="primary" htmlType="submit">
                        Set Password
                      </Button>
                    )}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
        <br />
        <FooterLinks />
      </div>
    </div>
  );
};

export default PasswordReset;
