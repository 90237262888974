import React from "react";
import { Typography, Row, Col } from "antd";
import Link from "antd/es/typography/Link";
import { useNavigate } from "react-router-dom";

const FooterLink = ({ link }) => {
  const navigate = useNavigate();
  return (
    <Col>
      <Link
        type="secondary"
        onClick={() => navigate(link.path)}
        style={{ padding: 3 }}
      >
        <small>{link.title}</small>
      </Link>
    </Col>
  );
};

const FooterLinks = () => {
  const links = [
    {
      path: "/about",
      title: "About",
    },
    {
      path: "/terms-and-conditions",
      title: "Terms & Conditions",
    },
    {
      path: "/cookie-policy",
      title: "Cookie Policy",
    },
    {
      path: "/privacy-policy",
      title: "Privacy Policy",
    },
  ];
  return (
    <div>
      <Row justify="center">
        {links.map((l) => (
          <FooterLink link={l} />
        ))}
      </Row>
      <div style={{ textAlign: "center", marginTop: 10 }}>
        <Typography.Text type="secondary">
          <small>
            © Parker Siddall 2024
          </small>
        </Typography.Text>
      </div>
    </div>
  );
};

export default FooterLinks;
