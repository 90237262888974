import React, { useContext } from "react";
import { Col, Row, Typography, Card, theme } from "antd";
import Container from "../../components/layout/container/container";
import MusicPlayerContext from "../../contexts/music-player-context";
import FooterLinks from "../../components/footer-links/footer-links";

const AboutPage = () => {

  const {
    token: { colorPrimary },
  } = theme.useToken();

  const { pause } = useContext(MusicPlayerContext);
  pause(); // stop playing music when page loads

  return (
    <Container>
      <div
        style={{
          padding: "12px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <div style={{ display: "block" }}>
          <Row justify="center">
            <Col xs={24} md={18} lg={12}>
              <Card>
                <div style={{ textAlign: "center" }}>
                  <Typography.Title
                    level={2}
                    style={{ color: colorPrimary }}
                    className="brand-title"
                  >
                    Jamtrackers
                  </Typography.Title>
                </div>
                <Typography.Title level={4}>About Jamtrackers</Typography.Title>
                <Typography.Paragraph>
                  Jamtrackers helps musicians stay fresh by providing them with
                  a flow of new practice material, chords, and other tools that
                  drive inspiration.
                </Typography.Paragraph>

                <Typography.Paragraph>
                  The concept is simple; the more you practice, the better
                  you’ll become. What many musicians struggle with, however, is
                  finding a way to practice that drives them outside of their
                  comfort zone. Every guitar player has their go-to chord
                  progression, which is naturally the first thing they play when
                  they pick up the guitar. Jamtrackers aims to break that habit
                  by stimulating musicians with new material.
                </Typography.Paragraph>

                <Typography.Paragraph>
                  We provide a platform where musicians can browse a feed of jam
                  tracks (also known as backing tracks). These tracks let
                  musicians exercise their improvisation skills and discover new
                  ways of playing. Perhaps the most powerful feature of
                  Jamtrackers is the transcription of our jam tracks. We analyze
                  each track that is uploaded by our users and extract the chord
                  progression. Users can then view the chord progression as they
                  listen to the jam track.
                </Typography.Paragraph>

                <Typography.Paragraph>
                  Jamtrackers is a social site, meaning that the content on the
                  platform is uploaded directly by the users themselves. We also
                  give users the possibility to follow others, creating a custom
                  feed filled with jam tracks that they know they will
                  appreciate.
                </Typography.Paragraph>

                <Typography.Paragraph>
                  While the principle feature of Jamtrackers is the feed of
                  backing tracks, the site also offers a number of additional
                  tools that can be helpful to practicing musicians. For
                  example, we provide guitar players with tools that let them
                  discover and identify unfamiliar chords.
                </Typography.Paragraph>

                <Typography.Paragraph>
                  Jamtrackers was created, developed and is maintained by{" "}
                  <a href="https://www.linkedin.com/in/parker-siddall/">
                    Parker Siddall
                  </a>
                  .
                </Typography.Paragraph>
              </Card>
              <br />
              <FooterLinks />
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default AboutPage;
