import { useContext } from "react";
import ReactPlayer from "react-player"
import MusicPlayerContext from "../../contexts/music-player-context";

const VideoPlayer = ({ src, mediaItem }) => {
    const {
        pause,
        isPlaying,
        current,
        setTrackProgress,
        startNew,
    } = useContext(MusicPlayerContext);

    const handlePlayVideo = () => {
        startNew(mediaItem)
    };

    const handleTrackProgress = (p) => {
        // avoid resetting progress if music
        // already playing
        if (current?.id === mediaId && isPlaying) {
            setTrackProgress(p.playedSeconds);
        }
    }

    // edit and new pages don't have ids
    // ID is needed to know when to stop playing
    const mediaId = mediaItem?.id ? mediaItem.id : Math.random() * 1000;

    return (
        <ReactPlayer
            url={src}
            width="100%"
            height={225}
            playing={current?.id === mediaId && isPlaying}
            onPlay={handlePlayVideo}
            onPause={() => pause()}
            onProgress={(p) => handleTrackProgress(p.playedSeconds)}
            volume={1}
            controls
        />
    )
}

export default VideoPlayer;