import { Card, Skeleton } from "antd";
import { useContext } from "react";
import AuthContext from "../../contexts/auth-context";

const SkeletonCourseCard = () => {
  const { isLoggedIn } = useContext(AuthContext);

  const actions = [];

  if (isLoggedIn) actions.push(<Skeleton.Button key={3} size="small" active />);
  return (
    <Card size="small">
      < Skeleton active paragraph={{ rows: 4 }} />
    </Card>
  );
};

export default SkeletonCourseCard;
