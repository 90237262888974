import React, { useContext } from "react";
import classes from "./video-capture.module.scss";
import {
  theme,
  Button,
  Col,
  Divider,
  Row,
  Space,
  Typography,
  Card,
  Badge,
} from "antd";
import AudioControls from "../../components/layout/audio-controls/audio-controls";
import Container from "../../components/layout/container/container";
import TrackProgressSlider from "../../components/layout/track-progress-slider";
import { capitalizeWord, formatFromChordDb } from "../../utils";
import Chord from "@tombatossals/react-chords/lib/Chord";
import { guitarChords, guitar } from "../../chords";
import MusicPlayerContext from "../../contexts/music-player-context";
import { useNavigate } from "react-router-dom";

const RibbonWrapper = ({ chord, children }) => {
  if (chord?.marker) {
    return (
      <Badge.Ribbon text={capitalizeWord(chord.marker)}>
        {children}
      </Badge.Ribbon>
    );
  }

  return children;
};

const VideoCaptureModal = () => {
  const navigate = useNavigate();
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const { current, currentNote } = useContext(MusicPlayerContext);

  const chords = currentNote
    ? guitarChords.chords[currentNote.note].find(
      (n) => n.suffix === currentNote.tonality
    ).positions
    : [];

  return (
    <div className={classes["modal-overlay"]}>
      <Container>
        <br />
        <Row justify="space-between">
          <Col>
            <Space>
              <AudioControls />
              <div style={{ width: 100 }}>
                <TrackProgressSlider />
              </div>
            </Space>
          </Col>
          <Col span={4}></Col>
          <Col>
            <Button onClick={() => navigate("/")}>Back to home</Button>
          </Col>
        </Row>
        <br />
        <Divider style={{ marginTop: 0 }} />
        <Row justify="center">
          <Col span={20}>
            <div className={classes["youtube-ratio-container"]}>
              <div className={classes["youtube-content"]}>
                <Row justify="center">
                  <Col>
                    <div style={{ textAlign: "center" }}>
                      <Typography.Title
                        level={2}
                        className="brand-title"
                        style={{ color: colorPrimary, marginBottom: 0 }}
                      >
                        Jamtrackers
                      </Typography.Title>
                      <Typography.Text type="secondary">
                        <small>
                          <b>www.jamtrackers.com</b>
                        </small>
                      </Typography.Text>
                    </div>
                  </Col>
                </Row>
                {current && (
                  <>
                    <Row gutter={[12, 12]}>
                      <Col span={24} style={{ height: 346 }}>
                        <Typography.Title level={5}>
                          Current Chord & Positions
                        </Typography.Title>
                        <RibbonWrapper chord={currentNote}>
                          <Card size="small">
                            {currentNote && (
                              <div style={{ textAlign: "center" }}>
                                <Typography.Title level={2}>
                                  {`${formatFromChordDb(currentNote.note)} ${currentNote.tonality
                                    }`}
                                </Typography.Title>
                              </div>
                            )}
                            {chords.length > 0 && (
                              <Row>
                                {chords.slice(0, 4).map((c) => {
                                  return (
                                    <Col span={6}>
                                      <Chord instrument={guitar} chord={c} />
                                    </Col>
                                  );
                                })}
                              </Row>
                            )}
                          </Card>
                        </RibbonWrapper>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <TrackProgressSlider showMarks />
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VideoCaptureModal;
